import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const ShortcutBox = ({ icon, title, subtitle, path, label }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const getLabelStyle = labelText => {
    if (labelText === 'Nytt') {
      return {
        backgroundColor: isDark ? '#2B4C2A' : '#C5F2B8',
        color: isDark ? '#3bbd28' : '#249314',
      };
    }
    return {
      backgroundColor: isDark ? '#2a3b4c' : '#e0e7ff',
      color: isDark ? '#60a5fa' : '#3b82f6',
    };
  };

  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.19)',
          borderRadius: '12px',
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          position: 'relative',
          '&:hover': {
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)',
            transform: 'translateY(-2px)',
            '& .icon, & .star': {
              color: isDark ? 'rgb(16, 151, 218)' : '#6b46c1',
            },
          },
        }}
      >
        {label && (
          <Chip
            label={label}
            size='small'
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              fontWeight: 'bold',
              fontSize: '0.7rem',
              height: '20px',
              ...getLabelStyle(label),
            }}
          />
        )}
        <StarBorderIcon
          className='star'
          sx={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            fontSize: 23,
            color: isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.5)',
            transition: 'color 0.3s ease',
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {typeof icon === 'string' ? (
            <img
              src={icon}
              alt={title}
              style={{
                width: 35,
                height: 35,
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: 16,
              }}
            />
          ) : (
            React.cloneElement(icon, {
              sx: {
                fontSize: 35,
                color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#8b5cf6',
                mr: 2,
                transition: 'color 0.3s ease',
              },
              className: 'icon',
            })
          )}
          <Box>
            <Typography
              variant='subtitle1'
              sx={{
                color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#4a5568',
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant='body2'
                sx={{
                  color: isDark ? 'rgba(255, 255, 255, 0.5)' : '#718096',
                  fontSize: 14,
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ShortcutBox;
