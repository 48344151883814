import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PromotionalWebsite from './PromotionalWebsite'; // Importera den nya välkomstsidan
import ExistingApp from './app/ExistingApp'; // Importera din befintliga app-struktur

function App({ isDark, toggleDarkMode, setOverrideTheme }) {
    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    {/* Välkomstsida på rot-URL */}
                    <Route
                        path='/'
                        element={
                            <PromotionalWebsite isDark={isDark} toggleDarkMode={toggleDarkMode} />
                        }
                    />

                    {/* Flytta befintlig app-struktur till /app */}
                    <Route
                        path='/app/*'
                        element={
                            <ExistingApp
                                isDark={isDark}
                                toggleDarkMode={toggleDarkMode}
                                setOverrideTheme={setOverrideTheme}
                            />
                        }
                    />

                    {/* Omdirigera odefinierade sökvägar till välkomstsidan */}
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
