import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Paper,
  Avatar,
  ListItemIcon, // Ensure ListItemIcon is imported if used
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import StarIcon from '@mui/icons-material/Star';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Helmet } from 'react-helmet-async';
// **Newly Added Icon Imports**
import { features } from './app/functions';

import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { motion, AnimatePresence } from 'framer-motion';
import { getAIModelImage } from './app/TestComponents2';

import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import FAQSection from './FAQSection';
import Logger from './app/Logger';
import TestimonialsSection from './TestimonialsSection';
import {
  GradientBackground,
  StyledButton,
  FeatureSection,
  FeatureList,
  ResponsiveVideo,
} from './StyledComponents';

const ModernStuderaAIWebsite = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const menuItems = [
    { label: 'Språk', sectionId: 'lär-dig-språk-med-ai' },
    { label: 'Kurser', sectionId: 'skapa-kurser-med-ai' },
    { label: 'Quiz', sectionId: 'quiz' },

    { label: 'Flashcards', sectionId: 'flashcards' },
    { label: 'Debatt', sectionId: 'ai-debatt' },
    { label: 'Högskoleprovet', sectionId: 'högskoleprovet' },
    { label: 'Karaktärer', sectionId: 'ai-lärare' },

    { label: 'Textlabbet', sectionId: 'textlabbet' },

    { label: 'FAQ', sectionId: 'faq' },
    { label: 'Om oss', sectionId: 'om-studera-ai' },
  ];

  return (
    <>
      <Helmet>
        <title>Studera.AI - Revolutionera ditt lärande med AI</title>
        <meta
          name='description'
          content='Upptäck framtidens lärande med Studera.AI. Personliga AI-lärare, quiz, flashcards och mer. Effektivisera dina studier och nå dina mål snabbare med vår innovativa AI-plattform.'
        />
      </Helmet>

      <Box sx={{ bgcolor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>
        <AppBar
          color='transparent'
          elevation={0}
          sx={{
            backdropFilter: 'blur(6px)',
            background:
              theme.palette.mode === 'dark'
                ? 'transparent'
                : 'linear-gradient(135deg, #200660 0%, #3D1368 100%)',
          }}
        >
          <Container maxWidth='xl'>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  component='img'
                  src='/img/AI.png'
                  alt='Studera.AI Logo'
                  sx={{ height: 30, marginRight: 2 }}
                />
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ fontWeight: 'bold', color: 'white' }}
                >
                  Studera.AI
                </Typography>
              </Box>
              {isMobile ? (
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  onClick={handleDrawerToggle}
                  sx={{ color: 'white' }}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {menuItems.map(item => (
                    <Button
                      key={item.sectionId}
                      onClick={() => scrollToSection(item.sectionId)}
                      sx={{
                        textTransform: 'none',
                        color: 'white',
                        fontWeight: '600',
                        '&:hover': { background: '#ffffff12' },
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          </Container>
        </AppBar>

        <Drawer
          anchor='right'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          <Box sx={{ width: 250, pt: 2 }} role='presentation' onClick={handleDrawerToggle}>
            <IconButton sx={{ ml: 1 }}>
              <CloseIcon />
            </IconButton>
            <List>
              {menuItems.map(item => (
                <ListItem
                  button
                  key={item.sectionId}
                  onClick={() => scrollToSection(item.sectionId)}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>

        <GradientBackground>
          <Container sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
            <Grid container spacing={6} alignItems='center'>
              <Grid item xs={12} md={5}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography
                    variant='h2'
                    component='h1'
                    gutterBottom
                    sx={{
                      fontWeight: 900,
                      letterSpacing: -1,
                      fontSize: { xs: '2.5rem', md: '3.35rem' },
                    }}
                  >
                    Studera smartare med AI
                  </Typography>
                  <Typography variant='h5' sx={{ mb: 4, opacity: 0.8, fontSize: '1.3rem' }}>
                    Utforska framtidens lärande med AI-teknologi. Personlig, engagerande och
                    effektiv.
                  </Typography>

                  <Typography variant='h5' sx={{ mb: 4, opacity: 0.8, fontSize: '1.3rem' }}>
                    <TypeAnimation
                      sequence={[
                        'Skapa AI-genererade quiz',
                        1000,
                        'Skapa kurser med AI',
                        1000,
                        'Skapa egna AI-karaktärer',
                        1000,
                        'Lär dig nya språk med AI',
                        1000,

                        'Träna på gamla högskoleprov',
                        1000,
                        'Träna på AI-genererade högskoleprov',
                        1000,
                        'Generera anpassade flashcards',
                        1000,
                        'Chatta med AI-lärare i realtid',
                        1000,
                        'Generera text med AI',
                        1000,
                        'Förbättra ditt ordförråd',
                        1000,
                        'Simulera debatter',
                        1000,
                        'Omvandla YouTube-föreläsningar till quiz',
                        1000,
                        'Analysera om en text är AI-genererad',
                        1000,
                        'Få förklaringar på komplexa ämnen',
                        1000,
                        'Visualisera koncept med AI',
                        1000,
                        'Röstsamtal med AI-karaktärer',
                      ]}
                      speed={50}
                      deletionSpeed={90}
                      style={{ fontSize: '1.3rem' }}
                      repeat={Infinity}
                    />
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', mb: 4 }}>
                    <Typography variant='body2' sx={{ color: 'white', mb: 1 }}>
                      Används av 3000+ studenter.
                    </Typography>
                    <Box>
                      {[...Array(5)].map((_, index) => (
                        <StarIcon key={index} sx={{ color: 'gold', fontSize: 20 }} />
                      ))}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Link to='/app' style={{ textDecoration: 'none' }}>
                      <StyledButton
                        variant='contained'
                        color='primary'
                        size='large'
                        endIcon={<ArrowForwardIcon />}
                      >
                        Börja använda Studera.AI nu
                      </StyledButton>
                    </Link>
                  </Box>
                </motion.div>
              </Grid>

              {/* Image on the right */}
              <Grid
                item
                xs={12}
                md={7}
                sx={{ position: 'relative' }}
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <Box
                      component='img'
                      src={theme.palette.mode === 'dark' ? '/img/d.png' : '/img/l.png'}
                      alt='Studera med AI'
                      sx={{
                        width: 'calc(100% + 100px)', // Stretch the image beyond its container
                        height: 'auto',
                        borderRadius: '7px',
                        position: 'relative',
                        right: '-50px', // Moves the image to touch the scrollbar
                      }}
                    />
                  </Link>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </GradientBackground>

        {features.map((feature, index) => {
          const hasModels = Boolean(feature.models && feature.models.length > 0);
          const isImageRight = index % 2 === 0;

          return (
            <FeatureSection
              key={index}
              id={feature.title.toLowerCase().replace(/ /g, '-')}
              sx={{
                bgcolor: index % 2 === 0 ? 'background.paper' : 'background.default',
                padding: { xs: '30px 0px', md: '80px 0px' },
              }}
            >
              <Container>
                <Grid
                  container
                  spacing={6}
                  alignItems='center'
                  direction={isImageRight ? 'row' : 'row-reverse'}
                >
                  {/* Text Content */}
                  <Grid
                    item
                    xs={12}
                    md={hasModels ? 12 : 6}
                    sx={{
                      textAlign: hasModels ? 'center' : 'left',
                    }}
                  >
                    <Typography
                      variant='h3'
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1.6em', md: '2.3em' },
                        textAlign: hasModels ? 'center' : 'left',
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant='h6'
                      paragraph
                      color='text.secondary'
                      sx={{
                        textAlign: hasModels ? 'center' : 'left',
                      }}
                    >
                      {feature.description}
                    </Typography>

                    {hasModels ? (
                      // If feature has models, render them
                      <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        sx={{ marginTop: hasModels ? '20px' : 'inherit' }}
                      >
                        {feature.models.map((model, idx) => (
                          <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card
                              sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 2,
                                boxShadow: 'none',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                  transform: 'translateY(-10px)',
                                },
                              }}
                            >
                              <CardContent sx={{ textAlign: 'center' }}>
                                <Box
                                  component='img'
                                  src={getAIModelImage(model.name)}
                                  alt={model.name}
                                  sx={{ width: 60, height: 60, mb: 2, borderRadius: '9px' }}
                                />
                                <Typography variant='h6' gutterBottom>
                                  {model.name}
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                  {model.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      // If feature has details, render them
                      <FeatureList
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'dark' ? 'transparent' : 'inherit',
                        }}
                      >
                        {feature.details.map((detail, idx) => (
                          <ListItem
                            key={idx}
                            alignItems='flex-start'
                            sx={{ marginLeft: '0px', paddingLeft: '0px' }}
                          >
                            <ListItemIcon
                              sx={{
                                '& > svg': {
                                  fontSize: '34px',
                                  color: theme.palette.mode === 'dark' ? '#9575F7' : '#6424c1',
                                },
                                minWidth: '40px',
                                marginRight: '15px',
                                marginLeft: '0px',
                              }}
                            >
                              {detail.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontWeight: '600', fontSize: '17px' }}>
                                  {detail.text.split(':')[0]}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{
                                    fontSize: '15px',
                                    color: theme.palette.mode === 'dark' ? '#8899a6' : 'gray',
                                  }}
                                >
                                  {detail.text.split(':')[1]}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </FeatureList>
                    )}

                    <Box mt={4} display='flex' justifyContent={hasModels ? 'center' : 'flex-start'}>
                      <Link to='/app' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                          color='primary'
                          endIcon={<ArrowForwardIcon />}
                        >
                          Lär dig mer
                        </StyledButton>
                      </Link>
                    </Box>
                  </Grid>

                  {/* Image or Video Content */}
                  {!hasModels && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        position: 'relative',
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      {feature.video ? (
                        // If feature has a video, render the video
                        <Paper
                          elevation={3}
                          sx={{
                            borderRadius: 2,
                            overflow: 'hidden',
                            background: 'none',
                            backgroundImage: 'none',
                            boxShadow: 'none',
                          }}
                        >
                          <ResponsiveVideo
                            light={feature.video.light}
                            dark={feature.video.dark}
                            theme={theme}
                            alt={`${feature.title} demonstration`}
                          />
                        </Paper>
                      ) : feature.img ? (
                        // If feature has an image, render the image
                        <motion.div
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, delay: 0.2 }}
                        >
                          <Link to='/app' style={{ textDecoration: 'none' }}>
                            <Box
                              component='img'
                              src={
                                theme.palette.mode === 'dark' ? feature.img.dark : feature.img.light
                              }
                              alt={`${feature.title} demonstration`}
                              sx={{
                                width: 'calc(100% + 100px)', // Stretch the image beyond its container
                                height: 'auto',
                                borderRadius: '7px',
                                position: 'relative',
                                ...(isImageRight
                                  ? { right: '-50px' } // Moves the image to touch the scrollbar on the right
                                  : { left: '-50px' }), // Moves the image to start at the left edge
                              }}
                            />
                          </Link>
                        </motion.div>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
              </Container>
            </FeatureSection>
          );
        })}

        <UniversitySection />

        <Container sx={{ my: 15, textAlign: 'center' }}>
          <Typography variant='h3' gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.3em' }}>
            Redo att revolutionera ditt lärande?
          </Typography>
          <Typography variant='h6' sx={{ mb: 4, color: 'text.secondary' }}>
            Anslut dig till 3000+ studenter som redan förbättrat sina studieresultat med Studera.AI
          </Typography>
          <Link to='/app' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='contained'
              color='primary'
              size='large'
              endIcon={<ArrowForwardIcon />}
            >
              Prova Studera.AI gratis nu
            </StyledButton>
          </Link>
        </Container>

        {/* Add the FAQ Section here, before the footer */}
        <Box id='faq'>
          <FAQSection />
        </Box>
        <Container id='om-studera-ai' sx={{ my: 15, textAlign: 'center' }}>
          <Typography variant='h3' gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.3em' }}>
            Om Studera.AI
          </Typography>

          <Typography
            variant='h6'
            sx={{ mb: 3, color: 'text.secondary', mt: 6, fontWeight: '600', fontSize: '1.15rem' }}
          >
            En ny era för lärande med AI
          </Typography>
          <Typography
            variant='body1'
            sx={{
              mb: 4,
              color: 'text.secondary',
              fontWeight: '400',
              fontSize: '1.15rem',
              lineHeight: '1.6',
            }}
          >
            Studera.AI föddes ur en vision om att revolutionera hur vi närmar oss lärande i en
            modern digital värld som i allt större utsträckning präglas av artificiell intelligens.
            Vi har skapat ett dynamiskt ekosystem där AI-drivna verktyg samverkar sömlöst för att
            stödja varje aspekt av studentens studieresa. Från AI-genererade quiz och flashcards
            baserade på ditt studiematerial, till simulerade debatter med historiska figurer och
            djupgående dialoger med specialiserade AI-lärare för att lära sig olika språk och ämnen
            som matematik och historia.
          </Typography>

          <Typography
            variant='h6'
            sx={{ mb: 3, color: 'text.secondary', mt: 6, fontWeight: '600', fontSize: '1.15rem' }}
          >
            Tillgänglig utbildning för alla
          </Typography>
          <Typography
            variant='body1'
            sx={{
              mb: 4,
              color: 'text.secondary',
              fontWeight: '400',
              fontSize: '1.15rem',
              lineHeight: '1.6',
            }}
          >
            Vår vision är att göra utbildning tillgänglig och anpassad för alla. Vi tror att alla
            studenter, oavsett bakgrund eller mål, ska ha tillgång till verktyg som hjälper dem att
            nå sin fulla potential. Genom att integrera avancerad AI-teknologi med pedagogiska
            metoder skapar vi en lärandemiljö som inspirerar och motiverar till kontinuerlig
            utveckling.
          </Typography>

          <Typography
            variant='h6'
            sx={{ mb: 3, color: 'text.secondary', mt: 6, fontWeight: '600', fontSize: '1.15rem' }}
          >
            AI som ett stöd, inte en genväg
          </Typography>
          <Typography
            variant='body1'
            sx={{
              mb: 4,
              color: 'text.secondary',
              fontWeight: '400',
              fontSize: '1.15rem',
              lineHeight: '1.6',
            }}
          >
            Studera.AI handlar om att ge eleverna verktyg för att lyckas, inte för att låta AI göra
            allt arbete åt dem. Vår filosofi är enkel: AI ska hjälpa till att förstärka elevens
            förståelse och uppmuntra dem till att tänka kritiskt och kreativt. Genom att använda
            våra AI-verktyg på ett smart och balanserat sätt, kan eleverna ta kontroll över sitt
            lärande och nå längre än de trodde var möjligt – med teknologin som ett stöd, inte en
            ersättning.
          </Typography>

          <Link to='/app' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='contained'
              color='primary'
              size='large'
              endIcon={<ArrowForwardIcon />}
            >
              Börja din resa nu
            </StyledButton>
          </Link>
        </Container>

        <TestimonialsSection />
        {/*<MediaSection />*/}
        <Box
          component='footer'
          sx={{
            bgcolor: 'background.default',
            py: 10,

            borderColor: 'divider',
            textAlign: 'center',
          }}
        >
          <Container maxWidth='sm'>
            <Grid container direction='column' alignItems='center' spacing={4}>
              <Grid item xs={12}>
                <Box component='img' src='/img/AI.png' alt='Studera.AI Logo' sx={{ height: 40 }} />
                <Typography variant='h6' gutterBottom>
                  Studera.AI
                </Typography>

                <Typography variant='body2' color='text.secondary'>
                  Vi revolutionerar lärandet med AI-teknologi för att göra utbildning mer
                  tillgänglig och effektiv för alla. Framtidens lärande är här. Utforska kraften i
                  AI-driven utbildning. Tillgänglig, effektiv och anpassningsbar för elever med
                  olika behov.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Kontakta oss
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  E-post: info@studera.ai
                </Typography>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant='body2' color='text.secondary' align='center'>
                © 2024 Studera.AI. Alla rättigheter förbehållna.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ModernStuderaAIWebsite;
