import React from 'react';
import { Box, Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const UniversityLogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',

  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light background
  borderRadius: '11px',
}));

const UniversityLogo = styled('img')(({ theme }) => ({
  width: 'auto', // Set width to 100% of the wrapper
  maxHeight: '100%', // Limit max height to the wrapper's height
  objectFit: 'contain',
}));

const MediaSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const universities = [
    { name: 'Svenska Dagbladet', logo: '/img/svd.png' },
    { name: 'Expressen', logo: '/img/expressen.png' },
    { name: 'Dagens industri', logo: '/img/di.png' },
    { name: 'Breakit', logo: '/img/breakit.png' },
    { name: 'Aftonbladet', logo: '/img/aftonbladet.png' },
    { name: 'Dagens Nyheter', logo: '/img/dn.png' },
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
      <Container maxWidth='lg'>
        {' '}
        {/* Use a larger max width */}
        <Typography
          variant='h3'
          align='center'
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2 }}
        >
          Studera.ai i media
        </Typography>
        <Typography variant='subtitle1' align='center' color='text.secondary' sx={{ mb: 6 }}>
          Vi har blivit omnämnda i bl.a. DN, SvD och Expressen
        </Typography>
        <Grid container spacing={isMobile ? 4 : 8} justifyContent='center' alignItems='center'>
          {universities.map((university, index) => (
            <Grid item xs={6} sm={4} md={2} key={university.name}>
              <UniversityLogoWrapper>
                <UniversityLogo
                  src={university.logo}
                  alt={`${university.name} logotyp`}
                  title={university.name}
                />
              </UniversityLogoWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MediaSection;
