import React from 'react';
import {
    Box,
    Drawer,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Button,
} from '@mui/material';
import { ClipLoader } from 'react-spinners';
import ChatIcon from '@mui/icons-material/Chat';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingSpinner from './LoadingSpinner';
import { ChevronRight } from '@mui/icons-material';
const ChatHistoryDrawer = ({
    drawerOpen,
    setDrawerOpen,
    oldishLoading,
    chats,
    editingChat,
    handleChatSelect,
    handleChatNameChange,
    handleSaveChatName,
    handleStartEditing,
    savingChatName,
    handleEditTeacher,
    isDark,
    styles,
}) => {
    return (
        <Drawer
            PaperProps={{
                sx: {
                    width: { xs: '75%', sm: '50%', md: '25%' },
                    boxShadow: 'none',
                    borderLeft: isDark ? '1px solid #34414c' : '1px solid #e0e0e0',
                    backgroundImage: 'none',
                },
            }}
            ModalProps={{
                BackdropProps: {
                    invisible: true,
                },
            }}
            anchor='right'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
        >
            <Box sx={{ p: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // This aligns children vertically
                    }}
                >
                    <IconButton
                        onClick={() => setDrawerOpen(false)}
                        color='inherit'
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // This ensures the icon is centered within the button
                            justifyContent: 'center',
                        }}
                    >
                        <ChevronRight />
                    </IconButton>
                    <Typography
                        variant='h6'
                        sx={{
                            fontSize: '17px',
                            ml: 2,
                            display: 'flex',
                            alignItems: 'center', // This ensures the text is vertically centered
                        }}
                    >
                        Historik
                    </Typography>
                </Box>

                {oldishLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'top',
                            justifyContent: 'top',
                            height: '100%',
                        }}
                    >
                        <LoadingSpinner noWrapper='true' />
                        <Typography variant='body1' sx={{ marginLeft: 1 }}></Typography>
                    </Box>
                ) : (
                    <List
                        sx={{
                            backgroundColor: isDark ? 'transparent' : 'inherit',
                        }}
                    >
                        {chats.map(chat => (
                            <ListItem
                                variant='compactList'
                                className='rounded'
                                key={chat.id}
                                button={editingChat.id !== chat.id}
                                onClick={() =>
                                    editingChat.id !== chat.id && handleChatSelect(chat.id)
                                }
                            >
                                <ListItemIcon>
                                    <ChatIcon />
                                </ListItemIcon>
                                {editingChat.id === chat.id ? (
                                    <>
                                        <TextField
                                            value={editingChat.name}
                                            onChange={handleChatNameChange}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleSaveChatName();
                                                }
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '4px',
                                                },
                                            }}
                                            autoFocus
                                            onClick={e => e.stopPropagation()}
                                        />
                                        {savingChatName ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginLeft: 1,
                                                }}
                                            >
                                                <ClipLoader size={20} color={'#123abc'} />
                                            </Box>
                                        ) : (
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleSaveChatName();
                                                }}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <ListItemText
                                            primary={chat.name}
                                            sx={{ paddingLeft: 0, marginLeft: 0 }}
                                        />
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleStartEditing(chat.id, chat.name);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </>
                                )}
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </Drawer>
    );
};

export default ChatHistoryDrawer;
