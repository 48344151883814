import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Avatar, IconButton } from '@mui/material';
import { collection, getDocs, query, where, limit, getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { useTheme } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Link } from 'react-router-dom';
import { formatUpvotes, flagUrls } from './functions';
import { CategoryCarouselSkeleton } from './TeacherSkeleton';

const CategoryCarousel = () => {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();

  const fetchCategories = async () => {
    const categoriesRef = collection(db, 'categories');
    const categoriesSnapshot = await getDocs(categoriesRef);
    const categoriesList = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(categoriesList);
    return categoriesList;
  };

  const fetchUserDisplayName = async userId => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data().displayName || 'anonym';
    }
    return 'anonym';
  };

  const fetchTeachersForCategory = async categoryId => {
    const teachersRef = collection(db, 'publicTeachers');
    const q = query(teachersRef, where('category', '==', categoryId), limit(4));
    const teachersSnapshot = await getDocs(q);
    const teachersList = teachersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const teachersWithDisplayNames = await Promise.all(
      teachersList.map(async teacher => {
        const displayName = await fetchUserDisplayName(teacher.userId);
        return { ...teacher, userDisplayName: displayName };
      })
    );

    return teachersWithDisplayNames;
  };

  const loadRandomCategory = async () => {
    setIsLoading(true);
    let categoriesList = categories;
    if (categoriesList.length === 0) {
      categoriesList = await fetchCategories();
    }

    let validCategory = null;
    let teachersList = [];

    while (!validCategory && categoriesList.length > 0) {
      const randomIndex = Math.floor(Math.random() * categoriesList.length);
      const randomCategory = categoriesList[randomIndex];
      teachersList = await fetchTeachersForCategory(randomCategory.id);

      if (teachersList.length > 0) {
        validCategory = randomCategory;
      } else {
        categoriesList.splice(randomIndex, 1);
      }
    }

    if (validCategory) {
      setCategories(categoriesList);
      setCurrentCategory(validCategory);
      setTeachers(teachersList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadRandomCategory();
  }, []);

  const handleRefresh = () => {
    loadRandomCategory();
  };

  if (isLoading || !currentCategory || teachers.length === 0) {
    return <CategoryCarouselSkeleton theme={theme} />;
  }

  if (!currentCategory) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        mb: 4,
        borderRadius: '16px',
        overflow: 'hidden',
        backgroundImage: 'none',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(29, 43, 55)' : 'rgb(223, 225, 227)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(29, 43, 55)' : 'rgb(223, 225, 227)',
          padding: 3,
          backdropFilter: 'blur(5px)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: '25px' }}>
            {currentCategory.name}
          </Typography>
          <IconButton onClick={handleRefresh} sx={{}}>
            <RefreshIcon />
          </IconButton>
        </Box>
        <Typography variant='body1' sx={{ mb: 3 }}>
          {currentCategory.description}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {teachers.map(teacher => (
            <Link
              key={teacher.id}
              to={`/app/chat/${teacher.id}`}
              style={{ textDecoration: 'none', width: 'calc(25% - 16px)' }}
            >
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundImage: 'none',
                  backgroundColor:
                    theme.palette.mode === 'dark' ? 'rgb(40, 54, 66)' : 'rgba(255, 255, 255, 0.37)',
                  backdropFilter: 'blur(10px)',
                  border:
                    theme.palette.mode === 'dark'
                      ? '1px solid rgb(64, 78, 91)'
                      : '1px solid rgb(208, 208, 208)',
                  boxShadow: 'none',
                  borderRadius: '12px',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box sx={{ position: 'relative' }}>
                        <Avatar
                          src={teacher.imageUrl}
                          alt={teacher.characterName}
                          sx={{ width: 50, height: 50, mr: 1 }}
                        />
                        {flagUrls[teacher.language] && (
                          <Box
                            component='img'
                            src={flagUrls[teacher.language]}
                            alt={`${teacher.language} `}
                            sx={{
                              position: 'absolute',
                              top: '-4px',
                              right: '6px',
                              width: '22px',
                              height: '22px',
                              borderRadius: '50%',
                              border: '1px solid white',
                            }}
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                          {teacher.characterName}
                        </Typography>
                        <Typography variant='body2' sx={{ fontSize: '13px' }}>
                          @{teacher.userDisplayName}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant='body2' sx={{ mb: 1 }}>
                      {teacher.description?.substring(0, 70)}...
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <ThumbUpOffAltIcon sx={{ fontSize: '14px' }} />
                        <Typography
                          variant='body2'
                          sx={{ fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                        >
                          {formatUpvotes(teacher.upvotes)}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton size='small' sx={{}}>
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryCarousel;
