import React, { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ProgressBar = ({ progress, label }) => {
  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = `${progress}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(progress)}%`;
    }
  }, [progress]);

  return (
    <Box sx={{ width: '100%', maxWidth: 690, mt: 2 }}>
      {label && (
        <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          width: '100%',
          height: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#283744' : '#f7f7f7',
          borderRadius: '14px',
          overflow: 'hidden',
        }}
      >
        <Box
          ref={progressRef}
          sx={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? '#6F38EA' : '#6339c4',
            transition: 'width 0.1s linear',
          }}
        />
      </Box>
      <Typography ref={progressPercentRef} variant='body2' color='text.secondary' align='right'>
        {Math.round(progress)}%
      </Typography>
    </Box>
  );
};

export default ProgressBar;
