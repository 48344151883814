import React, { useState, useEffect } from 'react';
import { Box, Drawer, Typography, IconButton, Divider } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import { format } from 'date-fns';

const SavedMessagesDrawer = ({ user, open, onClose, teacher, isDark }) => {
    const [savedMessages, setSavedMessages] = useState([]);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchSavedMessages = async () => {
            if (user && user.uid && teacher && teacher.id && open) {
                const q = query(
                    collection(db, 'notes'),
                    where('userId', '==', user.uid),
                    where('conversationType', '==', teacher.id),
                    orderBy('createdAt', 'desc')
                );
                const querySnapshot = await getDocs(q);
                const messages = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setSavedMessages(messages);
            }
        };

        fetchSavedMessages();
    }, [user, teacher, open]);

    const handleCopy = content => {
        navigator.clipboard.writeText(content);
        showSnackbar('Meddelande kopierat!', 'success');
    };

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: { xs: '75%', sm: '50%', md: '25%' },
                    boxShadow: 'none',
                    borderLeft: isDark ? '1px solid #34414c' : '1px solid #e0e0e0',
                    backgroundImage: 'none',
                },
            }}
        >
            <Box sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={onClose} color='inherit'>
                        <ChevronRight />
                    </IconButton>
                    <Typography variant='h6' sx={{ fontSize: '17px', ml: 2 }}>
                        Sparade meddelanden
                    </Typography>
                </Box>
                {savedMessages.map((message, index) => (
                    <React.Fragment key={message.id}>
                        <Box
                            sx={{ position: 'relative', mb: 2, p: 2, bgcolor: 'background.paper' }}
                        >
                            <Typography
                                variant='caption'
                                sx={{ fontSize: '0.7rem', color: 'text.secondary' }}
                            >
                                {format(message.createdAt.toDate(), 'yyyy-MM-dd HH:mm')}
                            </Typography>
                            <IconButton
                                onClick={() => handleCopy(message.content)}
                                size='small'
                                sx={{ position: 'absolute', top: 8, right: 8 }}
                            >
                                <ContentCopyIcon sx={{ fontSize: '0.9rem' }} />
                            </IconButton>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                {message.content.length > 100
                                    ? `${message.content.substring(0, 100)}...`
                                    : message.content}
                            </Typography>
                        </Box>
                        {index < savedMessages.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Box>
        </Drawer>
    );
};

export default SavedMessagesDrawer;
