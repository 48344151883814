import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Avatar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Subject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WikipediaIcon from '../WikipediaIcon';
import AIIcon from '@mui/icons-material/Psychology';
import LanguageIcon from '@mui/icons-material/Language';
import CategoryIcon from '@mui/icons-material/Category';
import DifficultyIcon from '@mui/icons-material/LinearScale';
import QuestionIcon from '@mui/icons-material/QuestionAnswer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PublicIcon from '@mui/icons-material/Public';
import TuneIcon from '@mui/icons-material/Tune';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import { useTheme } from '@mui/material/styles';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { getAIModelImage } from '../TestComponents2';
import PsychologyIcon from '@mui/icons-material/Psychology';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { getStyles } from '../../index';

const FeatureItem = ({ icon, title, description, examples, tips, useCase }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const BulletList = ({ items, icon: ItemIcon, color }) => (
    <Box>
      {items.map((item, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
          <ItemIcon sx={{ color, mr: 1, mt: 0.5 }} fontSize='small' />
          <Typography variant='body2'>{item}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Typography variant='h6' sx={{ ml: 2, fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body1' sx={{ mb: 2 }}>
          {description}
        </Typography>
        <Box sx={{ bgcolor: theme.palette.background.paper, p: 2, borderRadius: 2, mb: 2 }}>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mb: 1 }}>
            Användningsfall:
          </Typography>
          <Typography variant='body2'>{useCase}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
              Exempel:
            </Typography>
            <BulletList
              items={examples}
              icon={EmojiObjectsIcon}
              color={theme.palette.primary.main}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
              Tips:
            </Typography>
            <BulletList items={tips} icon={TuneIcon} color={theme.palette.secondary.main} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const QuizExplanation = () => {
  const theme = useTheme();

  const styles = getStyles(theme);
  return (
    <Box sx={{ mt: 8, mb: 6, width: '100%' }}>
      <Typography
        variant='h3'
        sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center', fontSize: '27px' }}
      >
        Upptäck kraften i AI-driven quizgenerering
      </Typography>
      <Typography
        variant='h5'
        sx={{ mb: 4, textAlign: 'center', color: theme.palette.text.secondary, fontSize: '18px' }}
      >
        Skapa engagerande, pedagogiska och skräddarsydda quiz för alla ändamål
      </Typography>

      <Grid container spacing={1} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              ...styles.newBox,
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: '15px',
            }}
          >
            <SchoolIcon
              sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2, alignSelf: 'center' }}
            />
            <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
              För lärare
            </Typography>
            <Typography variant='body1'>
              Revolutionera din undervisning med quiz som anpassar sig efter dina pedagogiska mål.
              Vår AI-drivna quizgenerator hjälper dig att skapa engagerande och effektiva läromedel
              som sparar tid och förbättrar inlärningsresultaten. Skräddarsy quiz för olika ämnen,
              nivåer och inlärningsstilar med enkla verktyg.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              ...styles.newBox,
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: '15px',
            }}
          >
            <MenuBookIcon
              sx={{ fontSize: 60, color: theme.palette.secondary.main, mb: 2, alignSelf: 'center' }}
            />
            <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
              För studenter
            </Typography>
            <Typography variant='body1'>
              Förbättra ditt lärande med personliga quiz som anpassar sig efter dina behov. Från
              grundskola till universitet, vår AI-drivna quizgenerator hjälper dig att skapa
              skräddarsydda studiematerial, öva på svåra koncept och förbereda dig inför prov.
              Upptäck ett roligare och mer effektivt sätt att lära dig nya ämnen och förbättra dina
              studieresultat.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 3, fontSize: '27px' }}>
        Kraftfulla funktioner för quizgenerering
      </Typography>

      <FeatureItem
        icon={<TopicIcon sx={{ color: theme.palette.primary.main }} />}
        title='Ämnesbaserad generering'
        description='Ange ett specifikt ämne eller koncept för att skapa ett skräddarsytt quiz. Vår AI analyserar ämnet och genererar relevanta frågor som testar olika aspekter av kunskapsområdet.'
        useCase='Perfekt för att snabbt skapa quiz om aktuella händelser, nya forskningsrön eller specifika kursmoment.'
        examples={[
          'Klimatförändringar och deras effekter på lokala ekosystem',
          'Artificiell intelligens i vardagen',
          'Shakespeares inflytande på modern litteratur',
        ]}
        tips={[
          'Kombinera breda och smala ämnen för en balanserad kunskapstest',
          'Använd aktuella händelser för att göra quizet mer relevant',
          'Inkludera tvärvetenskapliga kopplingar för att stimulera kritiskt tänkande',
        ]}
      />

      <FeatureItem
        icon={<TextSnippetIcon sx={{ color: theme.palette.secondary.main }} />}
        title='Textbaserad quizgenerering'
        description='Ladda upp Word- eller PDF-dokument eller klistra in längre texter för att generera frågor baserade på innehållet. Perfekt för att testa läsförståelse och detaljkunskap.'
        useCase='Idealiskt för att studera ett svårt kapitel i en bok, eller få ett quiz baserat på en läxa.'
        examples={[
          'Quiz baserat på de första 3 kapitlen i Vilhelm Mobergs Utvandrarna.',
          'Läsförståelsetest på en vetenskaplig artikel om kvantfysik',
          'Historisk analys baserad på primärkällor från andra världskriget',
        ]}
        tips={[
          'Använd texter med olika svårighetsgrader för progressiv inlärning',
          'Kombinera flera relaterade texter för att skapa sammanhangsfrågor',
          'Inkludera både faktabaserade och analytiska frågor för djupare förståelse',
        ]}
      />

      <FeatureItem
        icon={<WikipediaIcon sx={{ color: '#808080' }} />}
        title='Wikipedia-baserade quiz'
        description='Generera quiz baserade på Wikipedia-artiklar för att täcka ett brett spektrum av ämnen med pålitlig information.'
        useCase='Utmärkt för allmänbildning, förberedelse inför prov eller som introduktion till nya ämnesområden.'
        examples={[
          'Omfattande quiz om världens huvudstäder och geografi',
          'Vetenskapshistoria: från antiken till modern tid',
          'Nobelpristagare',
        ]}
        tips={[
          "Använd 'Relaterade artiklar' för att skapa quiz med bredare kontext",
          'Fokusera på specifika sektioner för mer specifika frågor',
          'Jämför information från flera språkversioner för kulturella perspektiv',
        ]}
      />

      <FeatureItem
        icon={<YouTubeIcon sx={{ color: theme.palette.error.main }} />}
        title='YouTube-baserad quizgenerering'
        description='Generera quiz baserat på YouTube-videor genom att ange en URL. Vår AI analyserar undertexterna och skapar relevanta frågor, vilket gör det enkelt att skapa engagerande quiz från videomaterial.'
        useCase='Perfekt för att förvandla videomaterial till interaktiva inlärningsupplevelser, oavsett om det gäller föreläsningar, dokumentärer, eller utbildningsvideos.'
        examples={[
          'Föreläsning från Stanfords YouTube-kanal',
          'Quiz baserat på en TED Talk om framtidens teknologier',
          'Frågesport från en historisk dokumentär om antikens Grekland',

          'Språkinlärningsquiz från en podcast på målspråket',
          'Sammanfattande frågor från en vetenskaplig YouTube-kanal om astronomi',
        ]}
        tips={[
          'Välj videos med tydliga undertexter för bästa resultat',
          'Använd längre videos för mer omfattande quiz och kortare för snabba kunskapskontroller',
          'Kombinera flera relaterade videos för att skapa ett mer omfattande quiz inom ett ämne',
          'Utnyttja funktionen för populärvetenskapliga videos för att göra komplexa ämnen mer tillgängliga',
          'Skapa quiz från instruktionsvideos för att testa praktisk förståelse av processer eller tekniker',
        ]}
      />

      <FeatureItem
        icon={<QuestionAnswerIcon sx={{ color: theme.palette.info.main }} />}
        title='Varierade frågetyper'
        description='Anpassa ditt quiz med olika typer av frågor för att testa kunskap och förståelse på olika sätt. Välj mellan flervalsfrågor, lucktext och sant/falskt-påståenden för att skapa engagerande och mångsidiga quiz.'
        useCase='Perfekt för att skapa varierade och omfattande kunskapstester som passar olika inlärningsstilar och ämnesområden.'
        examples={[
          'Flervalsfrågor: Ideal för historiska händelser eller vetenskapliga koncept',
          'Lucktext: Utmärkt för språkinlärning eller matematiska formler',
          'Sant/Falskt: Effektivt för att testa faktakunskaper inom alla ämnen',
        ]}
        tips={[
          'Kombinera olika frågetyper för att hålla quizet engagerande och utmanande',
          'Använd flervalsfrågor för komplexa ämnen med flera nyanser',
          'Integrera lucktext i längre passager för att testa läsförståelse',
          'Utnyttja sant/falskt-frågor för att snabbt täcka ett brett spektrum av ämnen',
        ]}
      />

      <FeatureItem
        icon={<PsychologyIcon sx={{ color: theme.palette.secondary.main }} />}
        title='Val av AI-modell'
        description='Välj mellan olika toppmoderna AI-modeller för att skräddarsy ditt quiz efter specifika behov och ämnesområden. Varje modell har sina unika styrkor och kan generera olika typer av frågor och innehåll.'
        useCase='Perfekt för att experimentera med olika AI-modeller och hitta den som bäst passar ditt ämne och målgrupp. Varje modell kan ge unika perspektiv och frågeformuleringar.'
        examples={[
          'Studera.AI: Specialanpassad AI för utbildningsändamål, optimerad för att skapa pedagogiska quiz och inlärningsuppgifter',
          'GPT-4 (Chat GPT 4o): Avancerad språkmodell med bred kunskap och förmåga att generera komplexa, kontextrika frågor',
          'GPT-4-Turbo (Chat GPT 4o-mini): Snabbare version av GPT-4 med uppdaterad kunskap, effektiv för varierade quizuppgifter',
          'Claude 3 Opus: Anthropics mest avancerade modell, utmärkt för detaljerade och nyanserade frågeställningar',
          'Claude 3 Sonnet: Balanserad modell från Anthropic, kombinerar effektivitet med hög kvalitet på output',
          'Gemini Pro: Googles kraftfulla multimodala AI, kapabel att integrera olika typer av information i quizgenerering',
          'Mistral Large: Open-source baserad modell känd för sin effektivitet och mångsidighet i textgenerering',
        ]}
        tips={[
          'Använd Studera.AI för pedagogiskt optimerade quiz anpassade för specifika utbildningsbehov',
          'Välj GPT-4 för djupgående och varierande frågor inom komplexa ämnesområden',
          'Prova GPT-4-Turbo för snabb generering av aktuella och varierade quizfrågor',
          'Använd Claude 3 Opus när du behöver extra detaljerade och välformulerade frågeställningar',
          'Testa Claude 3 Sonnet för en bra balans mellan kvalitet och generationshastighet',
          'Välj Gemini Pro för quiz som kräver integration av olika kunskapsdomäner',
          'Använd Mistral Large för pålitlig och effektiv quizgenerering, särskilt för öppna projekt',
        ]}
      />

      <FeatureItem
        icon={<CategoryIcon sx={{ color: theme.palette.warning.main }} />}
        title='Kategorispecifika quiz'
        description='Välj från en mängd fördefinierade kategorier eller skapa egna för att säkerställa att ditt quiz fokuserar på rätt kunskapsområde.'
        useCase='Perfekt för att generera quiz för dig med idétorka.'
        examples={[
          'STEM-quiz: En blandning av vetenskap, teknik, ingenjörskonst och matematik',
          'Konst genom tiderna: Från grottmålningar till digital konst',
          'Hållbar utveckling: Miljö, ekonomi och social rättvisa',
        ]}
        tips={[
          'Skapa tvärvetenskapliga kategorier för att uppmuntra holistiskt tänkande',
          'Använd underkategorier för att skapa progressiva svårighetsnivåer',
          'Balansera teoretiska och praktiska frågor inom varje kategori',
        ]}
      />

      <FeatureItem
        icon={<DifficultyIcon sx={{ color: theme.palette.success.main }} />}
        title='Anpassningsbar svårighetsgrad'
        description='Finjustera svårighetsgraden på ditt quiz för att passa olika kunskapsnivåer, från nybörjare till experter.'
        useCase='Idealiskt för differentierad undervisning, självstudier eller för att skapa utmanande tävlingar.'
        examples={[
          'Progressiv matematikquiz: Från grundläggande aritmetik till avancerad kalkyl',
          'Språkinlärningsquiz med ökande komplexitet i grammatik och vokabulär',
          "Kodningsutmaningar från 'Hello World' till avancerade algoritmer",
        ]}
        tips={[
          'Skapa quiz med dynamisk svårighetsgrad som anpassas efter användarens svar',
          'Inkludera förklaringar och resurser för svårare frågor',
          "Använd 'blandad svårighetsgrad' för att hålla användarna engagerade",
        ]}
      />

      <FeatureItem
        icon={<PublicIcon sx={{ color: theme.palette.info.light }} />}
        title='Delning och samarbete'
        description='Dela dina skapade quiz med andra användare, samarbeta i realtid på quizutveckling, och bygg upp en community av kunskapsdelning.'
        useCase='Idealiskt för lärarteam och studiegrupper som vill främja kunskapsdelning och samarbete.'
        examples={[
          'Skolövergripande quiz-bibliotek där lärare delar och återanvänder material',
          'Kollaborativt quiz-projekt för en tvärvetenskaplig universitetskurs',
        ]}
        tips={[
          'Implementera ett betygssystem för att lyfta fram högkvalitativa quiz',
          'Skapa tematiska samlingar av quiz för olika ämnesområden eller nivåer',
          'Uppmuntra användare att ge konstruktiv feedback för kontinuerlig förbättring',
        ]}
      />
    </Box>
  );
};

export default QuizExplanation;
