import React from 'react';
import { Box, Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import { Card, CardContent, CardHeader, Avatar } from '@mui/material';
import QuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    name: 'Anna Svensson',
    role: 'Student',
    feedback:
      'Studera.AI har förvandlat mitt sätt att förbereda mig för tentor. AI-genererade quiz och flashcards är perfekt!',
    avatar: '/avatars/anna.jpg',
  },
  {
    name: 'Lars Johansson',
    role: 'Universitetslärare',
    feedback:
      'Integreringen av Studera.AI i mina kurser har ökat studentengagemanget och förbättrat inlärningsresultaten.',
    avatar: '/avatars/lars.jpg',
  },
  {
    name: 'Emma Karlsson',
    role: 'Gymnasielärare',
    feedback:
      'Studera.AI har gjort mina språklektioner mer interaktiva och engagerande för eleverna.',
    avatar: '/avatars/emma.jpg',
  },
];

const TestimonialsSection = () => {
  const theme = useTheme();
  return (
    <Box sx={{ bgcolor: 'background.paper', py: 10 }}>
      <Container>
        <Typography variant='h4' align='center' gutterBottom sx={{ fontWeight: '700', mb: 2 }}>
          Vad våra användare säger
        </Typography>
        <Typography variant='subtitle1' align='center' color='text.secondary' sx={{ mb: 6 }}>
          Läs vad studenter och lärare tycker om Studera.AI
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: 'none',
                  bgcolor: 'background.paper',
                  backgroundImage: 'none',
                }}
              >
                <CardContent>
                  <QuoteIcon
                    color='primary'
                    sx={{
                      fontSize: 40,
                      mb: 2,
                      color: theme.palette.mode === 'dark' ? '#4e6479' : '#c4c8cc',
                    }}
                  />
                  <Typography
                    variant='body1'
                    color='text.primary'
                    sx={{ fontStyle: 'italic', mb: 2 }}
                  >
                    "{testimonial.feedback}"
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                        {testimonial.name}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {testimonial.role}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TestimonialsSection;
