import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Divider,
  Select,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import { Link } from 'react-router-dom';
import { getStyles } from '../index';
import SegmentIcon from '@mui/icons-material/Segment';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';

const Rubrics = ({ user }) => {
  const [rubricName, setRubricName] = useState('');
  const [criteria, setCriteria] = useState([{ name: '', descriptors: [{ text: '', grade: 'A' }] }]);
  const { showSnackbar } = useSnackbar();
  const [isExample, setIsExample] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isSaving, setIsSaving] = useState(false);
  const handleAddCriteria = () => {
    setCriteria([...criteria, { name: '', descriptors: [{ text: '', grade: 'A' }] }]);
  };

  const handleRemoveCriteria = index => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    setCriteria(newCriteria);
  };

  const handleAddDescriptor = criteriaIndex => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors.push({ text: '', grade: 'A' });
    setCriteria(newCriteria);
  };

  const handleRemoveDescriptor = (criteriaIndex, descriptorIndex) => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors = newCriteria[criteriaIndex].descriptors.filter(
      (_, i) => i !== descriptorIndex
    );
    setCriteria(newCriteria);
  };

  const handleCriteriaChange = (index, value) => {
    const newCriteria = [...criteria];
    newCriteria[index].name = value;
    setCriteria(newCriteria);
  };

  const handleDescriptorChange = (criteriaIndex, descriptorIndex, field, value) => {
    const newCriteria = [...criteria];
    newCriteria[criteriaIndex].descriptors[descriptorIndex][field] = value;
    setCriteria(newCriteria);
  };

  const handleSaveRubric = async () => {
    // Check if all visible fields are filled
    if (rubricName.trim() === '') {
      showSnackbar('Vänligen fyll i namnet på bedömningsmatrisen', 'error');
      return;
    }

    const isValid = criteria.every(
      criterion =>
        criterion.name.trim() !== '' &&
        criterion.descriptors.every(descriptor => descriptor.text.trim() !== '')
    );

    if (!isValid) {
      showSnackbar('Vänligen fyll i alla fält innan du sparar', 'error');
      return;
    }

    setIsSaving(true);
    try {
      await addDoc(collection(db, 'rubrics'), {
        name: rubricName,
        custom: false,
        criteria: criteria,
        createdAt: new Date(),
        userId: user.uid,
      });
      showSnackbar('Bedömningsmatrisen har sparats', 'success');
      // Reset form
      setRubricName('');
      setCriteria([{ name: '', descriptors: [{ text: '', grade: 'A' }] }]);
    } catch (error) {
      console.error('Error saving rubric:', error);
      showSnackbar('Ett fel uppstod när bedömningsmatrisen skulle sparas', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleExampleClick = () => {
    if (isExample) {
      // Reset the form
      setRubricName('');
      setCriteria([{ name: '', descriptors: [{ text: '', grade: 'A' }] }]);
      setIsExample(false);
    } else {
      setRubricName('Argumenterande');
      setCriteria([
        {
          name: 'Argumentation',
          descriptors: [
            {
              text: 'Starka, väl underbyggda argument som övertygar läsaren. Motargument bemöts effektivt.',
              grade: 'A',
            },
            {
              text: 'Tydlig argumentation med stödjande exempel, men vissa argument kan utvecklas mer.',
              grade: 'C',
            },
            {
              text: 'Grundläggande argumentation, men vissa argument är svaga eller saknar stöd.',
              grade: 'E',
            },
            {
              text: 'Bristfälliga eller frånvarande argument. Ingen tydlig linje i resonemanget.',
              grade: 'F',
            },
          ],
        },
        {
          name: 'Disposition',
          descriptors: [
            { text: 'Logisk och tydlig struktur med starka övergångar mellan idéer.', grade: 'A' },
            { text: 'Struktur finns, men vissa övergångar är otydliga.', grade: 'C' },
            { text: 'Acceptabel struktur, men vissa avsnitt är svåra att följa.', grade: 'E' },
            {
              text: 'Oorganiserad och svår att följa; ingen tydlig inledning, kropp eller avslutning.',
              grade: 'F',
            },
          ],
        },
        {
          name: 'Språk och stil',
          descriptors: [
            { text: 'Välutvecklat språk, stilistiskt övertygande och engagerande.', grade: 'A' },
            { text: 'Bra språk med några mindre språkliga fel.', grade: 'C' },
            { text: 'Enkelt men begripligt språk med några fel som påverkar flytet.', grade: 'E' },
            { text: 'Många språkliga fel som försvårar förståelsen.', grade: 'F' },
          ],
        },
        {
          name: 'Källhantering',
          descriptors: [
            {
              text: 'Effektiv och korrekt användning av källor som stärker argumenten.',
              grade: 'A',
            },
            { text: 'Källor används, men vissa är tveksamma eller saknas.', grade: 'C' },
            {
              text: 'Grundläggande användning av källor, men vissa referenser saknas eller är felaktiga.',
              grade: 'E',
            },
            { text: 'Ingen eller felaktig användning av källor.', grade: 'F' },
          ],
        },
      ]);
      setIsExample(true);
    }
  };

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={styles.innerContainer}>
        <Typography variant='h1' sx={{ ...styles.header, borderBottom: '0px' }}>
          Bedömningsmatriser
        </Typography>

        <Typography variant='h2' sx={{ ...styles.header, borderBottom: '0px', fontSize: '1.3rem' }}>
          Skapa bedömningsmatris
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Button
            variant={'contained'}
            color='primary'
            component={Link}
            startIcon={<AddIcon />}
            to='/app/uppsats/ny'
          >
            Lägg till uppsats
          </Button>
          <Button
            variant='contained'
            color='primary'
            component={Link}
            startIcon={<SegmentIcon />}
            to='/app/uppsatser'
          >
            Dina uppsatser
          </Button>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '20px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              onClick={handleExampleClick}
              variant='outlined'
              sx={{
                ...styles.beautyBtn,
                ...styles.exampleBtn,
              }}
            >
              {isExample ? 'Nollställ' : 'Exempel'}
            </Button>
          </Box>

          <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '400' }}>
            Namn på bedömningsmatris:
          </Typography>
          <TextField
            fullWidth
            placeholder='Namn'
            value={rubricName}
            onChange={e => setRubricName(e.target.value)}
            margin='normal'
            sx={{ mt: 1 }}
          />
          {criteria.map((criterion, criteriaIndex) => (
            <Box
              key={criteriaIndex}
              elevation={3}
              sx={{ ...styles.freshBox, mt: 2, padding: '20px' }}
            >
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '400' }}>
                Titel på kriterium:
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, mt: 1 }}>
                <TextField
                  fullWidth
                  placeholder={`Kriterium ${criteriaIndex + 1}`}
                  value={criterion.name}
                  onChange={e => handleCriteriaChange(criteriaIndex, e.target.value)}
                />
                <IconButton onClick={() => handleRemoveCriteria(criteriaIndex)} color='error'>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box display='flex' justifyContent='flex-start' sx={{ mb: 2 }}>
                <Typography variant='caption' sx={{ ...styles.grayText }}>
                  De aspekter av skrivprestationen som kommer att bedömas. Till exempel:
                  Argumentation, Bevisföring eller Tydlighet
                </Typography>
              </Box>
              {criterion.descriptors.map((descriptor, descriptorIndex) => (
                <Box key={descriptorIndex} sx={{ marginLeft: 5, marginBottom: 1 }}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '400' }}>
                    Beskrivning:
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      placeholder='Beskrivning'
                      value={descriptor.text}
                      onChange={e =>
                        handleDescriptorChange(
                          criteriaIndex,
                          descriptorIndex,
                          'text',
                          e.target.value
                        )
                      }
                      sx={{ marginRight: 1 }}
                    />
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                    >
                      <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 1 }}>
                        Betyg:
                      </Typography>
                      <Select
                        value={descriptor.grade}
                        onChange={e =>
                          handleDescriptorChange(
                            criteriaIndex,
                            descriptorIndex,
                            'grade',
                            e.target.value
                          )
                        }
                        sx={{ width: '100px', marginRight: 1 }}
                      >
                        <MenuItem value='A'>A</MenuItem>
                        <MenuItem value='C'>C</MenuItem>
                        <MenuItem value='E'>E</MenuItem>
                        <MenuItem value='F'>F</MenuItem>
                      </Select>
                    </Box>
                    <IconButton
                      onClick={() => handleRemoveDescriptor(criteriaIndex, descriptorIndex)}
                      color='error'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Box display='flex' justifyContent='flex-start' sx={{ mb: 2 }}>
                    <Typography variant='caption' sx={{ ...styles.grayText }}>
                      Ange vad uppsatsen behöver visa för att få detta betyg. T.ex.: Uppsatsen är
                      välstrukturerad.
                    </Typography>
                  </Box>
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                variant='outlined'
                onClick={() => handleAddDescriptor(criteriaIndex)}
                sx={{ marginTop: 1, ml: 5 }}
              >
                Lägg till beskrivning
              </Button>
            </Box>
          ))}
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={handleAddCriteria}
            sx={{ marginTop: 2 }}
          >
            Lägg till kriterium
          </Button>
          <Divider sx={{ margin: '20px 0' }} />
          <Button
            startIcon={isSaving ? <CircularProgress size={16} color='inherit' /> : <SaveIcon />}
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleSaveRubric}
            sx={{ marginTop: 2 }}
            disabled={isSaving}
          >
            {isSaving ? 'Sparar...' : 'Spara bedömningsmatris'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Rubrics;
