import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Paper,
  Input,
  Menu,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { callApiWithStreaming } from './api'; // Adjust import path as needed
import PulsatingDot from './PulsatingDot'; // Adjust import path as needed
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CompressIcon from '@mui/icons-material/Compress';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ExpandIcon from '@mui/icons-material/Expand';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import GavelIcon from '@mui/icons-material/Gavel';
import LinkIcon from '@mui/icons-material/Link';
import RepeatIcon from '@mui/icons-material/Repeat';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import QuizIcon from '@mui/icons-material/Quiz';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import LanguageIcon from '@mui/icons-material/Language';
import UpdateIcon from '@mui/icons-material/Update';
import HistoryIcon from '@mui/icons-material/History';
import StopIcon from '@mui/icons-material/Stop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BarChartIcon from '@mui/icons-material/BarChart';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BrushIcon from '@mui/icons-material/Brush';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import mammoth from 'mammoth';
import { getStyles } from '../index';
import Suggestions from './Suggestions';
import WordIcon from './Icons/WordIcon';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import IdeaGenerator from './IdeaGenerator';
import Logger from './Logger';
import { useLocation } from 'react-router-dom';
// New imports for export functionality
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';

const TextManager = ({ user }) => {
  const [outputText, setOutputText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const abortControllerRef = useRef(null);
  const outputBoxRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [inputLength, setInputLength] = useState(0);
  const maxInputLength = 2000;
  const [anchorEl, setAnchorEl] = useState(null);

  const subjectInputRef = useRef(null);
  const [visibleButtons, setVisibleButtons] = useState([]);
  const [showAllButtons, setShowAllButtons] = useState(true);
  const [actionText, setActionText] = useState('2. Välj vad du vill göra');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sourceParam = params.get('function');

    if (sourceParam) {
      setShowAllButtons(false);
      switch (sourceParam) {
        case 'variera':
          setVisibleButtons(['Variera']);
          setActionText('2. Variera');
          break;
        case 'summera':
          setVisibleButtons(['Summera']);
          setActionText('2. Summera');
          break;
        case 'ratta':
          setVisibleButtons(['Rätta']);
          setActionText('2. Rätta');
          break;
        case 'formatera':
          setVisibleButtons(['Formatera']);
          setActionText('2. Formatera');
          break;
        case 'faktakolla':
          setVisibleButtons(['Faktakolla']);
          setActionText('2. Faktakolla');
          break;
        case 'punktlista':
          setVisibleButtons(['Punktlista']);
          setActionText('2. Punktlista');
          break;
        case 'forbattra':
          setVisibleButtons(['Förbättra']);
          setActionText('2. Förbättra');
          break;
        case 'kritisera':
          setVisibleButtons(['Kritisera']);
          setActionText('2. Kritisera');
          break;
        case 'omstrukturera':
          setVisibleButtons(['Omstrukturera']);
          setActionText('2. Omstrukturera');
          break;
        case 'argumentanalysera':
          setVisibleButtons(['Argumentationsanalysera']);
          setActionText('2. Argumentationsanalys');
          break;
        case 'konceptideer':
          setVisibleButtons(['Jämför koncept & idéer']);
          setActionText('2. Jämför koncept & idéer');
          break;
        case 'intertextuellanalys':
          setVisibleButtons(['Intertextuell analys']);
          setActionText('2. Intertextuell analys');
          break;
        case 'generera-studieuppgifter':
          setVisibleButtons(['Generera studieuppgifter']);
          setActionText('2. Generera studieuppgifter');
          break;
        case 'generera-flervalsfragor':
          setVisibleButtons(['Generera flervalsfrågor']);
          setActionText('2. Generera flervalsfrågor');
          break;
        default:
          setVisibleButtons([]);
          setShowAllButtons(true);
          setActionText('2. Välj vad du vill göra');
          break;
      }
    } else {
      setVisibleButtons([]);
      setShowAllButtons(true);
      setActionText('2. Välj vad du vill göra');
    }
    window.scrollTo(0, 0);
  }, [location]);

  const handleExportClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);

    // Konvertera HTML till ren text och bevara radbrytningar
    const lines = outputText
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<[^>]+>/g, '')
      .split('\n');

    let y = 20;
    lines.forEach(line => {
      if (y > 280) {
        // Om vi närmar oss sidans slut
        doc.addPage(); // Lägg till en ny sida
        y = 20; // Återställ y-positionen
      }
      doc.text(line, 10, y);
      y += 7; // Öka y-positionen för nästa rad
    });

    doc.save('textlabbet_resultat.pdf');
    handleExportClose();
  };

  const exportToWord = () => {
    // Konvertera HTML till ren text och bevara radbrytningar
    const textContent = outputText.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '');

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: 'Textlabbet Resultat',
              heading: HeadingLevel.HEADING_1,
            }),
            ...textContent.split('\n').map(
              line =>
                new Paragraph({
                  children: [new TextRun(line)],
                })
            ),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, 'textlabbet_resultat.docx');
    });
    handleExportClose();
  };

  const handleIdeaGenerated = idea => {
    if (subjectInputRef.current) {
      subjectInputRef.current.value = idea;
      setInputLength(idea.length);
    }
  };

  const handleInputChange = event => {
    setInputLength(event.target.value.length);
  };

  const buttons = [
    {
      label: 'Variera',
      icon: ShuffleIcon,
      instruction: 'Variera texten utan att ändra innebörden.',
    },
    { label: 'Summera', icon: SummarizeIcon, instruction: 'Sammanfatta texten kortfattat.' },
    {
      label: 'Formatera',
      icon: FormatAlignLeftIcon,
      instruction:
        'Ge texten bättre formatering, t.ex. rubriker, stycken och fetstil för att förbättra läsbarheten.',
    },
    { label: 'Komplicera', icon: BuildIcon, instruction: 'Gör texten mer komplex och avancerad.' },
    {
      label: 'Förenkla',
      icon: AutoFixHighIcon,
      instruction: 'Förenkla texten så att den blir lättare att förstå.',
    },

    {
      label: 'Faktakolla',
      icon: FactCheckIcon,
      instruction:
        'Identifiera påståenden i texten och försök verifiera dem mot tillförlitliga källor.',
    },
    {
      label: 'Punktlista',
      icon: FormatListBulletedIcon,
      instruction: 'Omvandla texten till en tydlig punktlista.',
    },

    {
      label: 'Förbättra',
      icon: TrendingUpIcon,
      instruction: 'Förbättra textens flyt och ton, gör den mer engagerande.',
    },
    {
      label: 'Metaforifiera',
      icon: BrushIcon,
      instruction: 'Infoga fler metaforer och bildspråk för en mer målande text.',
    },
    {
      label: 'Krydda',
      icon: LocalFloristIcon,
      instruction: 'Lägg till starkare adjektiv och adverb för en mer färgstark text.',
    },

    {
      label: 'Förkorta',
      icon: CompressIcon,
      instruction: 'Korta ner texten utan att tappa viktigt innehåll.',
    },
    {
      label: 'Förlänga',
      icon: ExpandIcon,
      instruction: 'Utöka texten med mer detaljer och förklaringar.',
    },
    { label: 'Parafrasera', icon: RepeatIcon, instruction: 'Omformulera texten med andra ord.' },
    {
      label: 'Analysera',
      icon: AnalyticsIcon,
      instruction: 'Analysera textens innehåll, struktur och budskap.',
    },
    {
      label: 'Kritisera',
      icon: RateReviewIcon,
      instruction: 'Ge konstruktiv kritik på textens innehåll och upplägg.',
    },
    {
      label: 'Omstrukturera',
      icon: ViewStreamIcon,
      instruction: 'Ändra textens struktur för bättre flöde och läsbarhet.',
    },
    {
      label: 'Kontextualisera',
      icon: LanguageIcon,
      instruction: 'Sätt texten i ett bredare sammanhang.',
    },
    {
      label: 'Modernisera',
      icon: UpdateIcon,
      instruction: 'Uppdatera texten till ett mer modernt språkbruk.',
    },
    {
      label: 'Arkaisera',
      icon: HistoryIcon,
      instruction: 'Omforma texten till ett äldre språkbruk.',
    },
    {
      label: 'Rätta',
      icon: SpellcheckIcon,
      instruction:
        'Rätta stavfel och grammatiska fel i texten. Stavfel markeras med understrykning, grammatiska fel med fetstil, och andra typer av fel på lämpligt sätt.',
    },
    {
      label: 'Konceptualisera',
      icon: LightbulbIcon,
      instruction: 'Abstrahera texten till dess grundläggande koncept och idéer.',
    },
    {
      label: 'Brainstorma',
      icon: EmojiObjectsIcon,
      instruction: 'Generera idéer och tankar relaterade till textens ämne.',
    },
    {
      label: 'Ordfrekvensberäkna',
      icon: BarChartIcon,
      instruction:
        'Analysera ordanvändning och frekvens i texten. Skriv ut exakt hur många ord det finns om det finns väldigt många liknande ord.',
    },
    {
      label: 'Humorisera',
      icon: SentimentVerySatisfiedIcon,
      instruction: 'Lägg till humoristiska inslag i texten.',
    },
    {
      label: 'Dramatisera',
      icon: TheaterComedyIcon,
      instruction: 'Gör texten mer dramatisk och känsloladdad.',
    },
    {
      label: 'Poetisera',
      icon: MusicNoteIcon,
      instruction: 'Omvandla texten till en poetisk form med rim och rytm.',
    },

    {
      label: 'Argumentationsanalysera',
      icon: GavelIcon,
      instruction: 'Identifiera och utvärdera argument som presenteras i texten.',
    },
    {
      label: 'Jämför koncept & idéer',
      icon: CompareArrowsIcon,
      instruction: 'Skapa en strukturerad jämförelse mellan olika koncept eller idéer i texten.',
    },

    {
      label: 'Intertextuell analys',
      icon: LinkIcon,
      instruction: 'Föreslå kopplingar mellan texten och andra relevanta texter eller verk.',
    },

    {
      label: 'Generera studieuppgifter',
      icon: AssignmentIcon,
      instruction: 'Skapa studieuppgifter och diskussionsfrågor baserade på textens innehåll.',
    },
    {
      label: 'Generera flervalsfrågor',
      icon: QuizIcon,
      instruction:
        'Skapa flervalsfrågor (A-E) baserade på textens innehåll. Strukturera det som ett prov med facit i slutet.',
    },
    {
      label: 'Generera fyll i luckan-frågor',
      icon: TextFieldsIcon,
      instruction:
        'Skapa "Fylla i luckan"-frågor baserade på textens innehåll. Strukturera det som ett prov med facit i slutet.',
    },
  ];

  useEffect(() => {
    if (outputBoxRef.current) {
      outputBoxRef.current.scrollTop = outputBoxRef.current.scrollHeight;
    }
  }, [outputText]);

  const handleButtonClick = async (instruction, label) => {
    if (isProcessing) {
      abortControllerRef.current.abort();
      setIsProcessing(false);
      setActiveButton(null);
      return;
    }

    setIsProcessing(true);
    setActiveButton(label);
    setOutputText('');
    abortControllerRef.current = new AbortController();

    try {
      let streamingContent = '';
      const onChunk = chunk => {
        streamingContent += chunk;
        const htmlContent = marked(streamingContent);
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        setOutputText(sanitizedHtml);
      };

      await callApiWithStreaming(
        [
          {
            role: 'system',
            content: 'Du är en hjälpsam assistent som modifierar text enligt instruktioner.',
          },
          {
            role: 'user',
            content: `${instruction} Här är texten: ${subjectInputRef.current.value}`,
          },
        ],
        onChunk,
        user.uid,
        abortControllerRef.current.signal
      );
    } catch (error) {
      if (error.name === 'AbortError') {
        Logger.log('Stream was aborted');
      } else {
        console.error('Error calling AI API:', error);
        setOutputText('Ett fel uppstod vid kommunikation med AI. Vänligen försök igen.');
      }
    } finally {
      setIsProcessing(false);
      setActiveButton(null);
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(outputText.replace(/<[^>]+>/g, ''));
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [outputText]);

  const handleSelect = selected => {
    if (selected.text) {
      if (subjectInputRef.current) {
        subjectInputRef.current.value = selected.value;
      }
    }
  };

  const getExampleForFunction = label => {
    switch (label) {
      case 'Variera':
        return '"Katten satt på mattan" kan bli "Den lurviga fyrbenta vännen vilade på golvbeklädnaden."';
      case 'Summera':
        return 'En lång artikel om klimatförändringar kan sammanfattas till dess huvudpunkter och slutsatser.';
      case 'Komplicera':
        return '"Solen skiner" kan utvecklas till "Det celestiala objektet emitterar elektromagnetisk strålning som penetrerar atmosfären."';
      case 'Förenkla':
        return '"Den komplexa metaboliska processen" kan förenklas till "Hur kroppen bryter ner mat."';
      case 'Förkorta':
        return 'En omfattande projektbeskrivning kan kortas ner till de mest väsentliga punkterna.';
      case 'Förlänga':
        return '"Sverige är vackert" kan utökas med beskrivningar av landskap, kultur och sevärdheter.';
      case 'Parafrasera':
        return '"Det regnar ute" kan omformuleras till "Himlen gråter sina droppar över marken."';
      case 'Analysera':
        return 'En dikt kan analyseras för dess teman, bildspråk och struktur.';
      case 'Kritisera':
        return 'En uppsats kan granskas för dess argument, källanvändning och struktur.';
      case 'Omstrukturera':
        return 'En kronologisk berättelse kan omstruktureras för att börja in medias res.';
      case 'Kontextualisera':
        return 'En historisk händelse kan sättas i sitt bredare sociala och politiska sammanhang.';
      case 'Modernisera':
        return 'En text från 1800-talet kan uppdateras med modernt språkbruk och referenser.';
      case 'Arkaisera':
        return 'En modern nyhet kan omskrivas i stil med en 1700-tals pamflett.';
      case 'Rätta':
        return 'En text med stavfel som "Jag ska gå till skolan imorgon" kan korrigeras till "Jag ska gå till skolan i morgon" med understrykningar för rättade fel.';
      case 'Konceptualisera':
        return 'En detaljerad beskrivning av en smartphone kan abstraheras till koncept som "kommunikation", "teknologi" och "mobilitet".';
      case 'Brainstorma':
        return 'För en text om klimatförändringar kan relaterade idéer som "förnybara energikällor", "koldioxidneutralitet" och "cirkulär ekonomi" genereras.';
      case 'Ordfrekvensberäkna':
        return 'I en text kan ordfrekvensen analyseras, t.ex. "och: 15, att: 12, det: 10" för att visa de mest använda orden.';
      case 'Humorisera':
        return 'En torr nyhetsartikel kan omvandlas till en rolig sketch.';
      case 'Dramatisera':
        return 'En enkel beskrivning av en promenad kan bli till en spännande äventyrsberättelse.';
      case 'Poetisera':
        return 'En vardaglig konversation kan bli till en lyrisk dikt.';
      case 'Faktakolla':
        return 'En text om klimatförändringar kan få påståenden verifierade med aktuell forskning.';
      case 'Punktlista':
        return 'En lång beskrivande text om ett ämne kan omvandlas till en koncis punktlista med huvudpoänger.';
      case 'Förbättra':
        return 'En torr, faktaspäckad text kan förvandlas till en engagerande berättelse som fångar läsarens intresse.';
      case 'Metaforifiera':
        return '"Ekonomin växer" kan bli "Landets ekonomi blomstrar som en välskött trädgård på våren."';
      case 'Krydda':
        return '"Huset var stort" kan bli "Den majestätiska herrgården tornade upp sig mot himlen."';
      case 'Generera flervalsfrågor':
        return 'En text om solsystemet kan generera frågor som "Vilken planet är störst? A) Jorden B) Mars C) Jupiter D) Saturnus E) Neptunus" med Jupiter som korrekt svar.';

      case 'Formatera':
        return 'En lång text utan struktur kan få rubriker, underrubriker, styckeindelningar och viktiga delar i fetstil.';
      case 'Generera studieuppgifter':
        return 'En text om fotosyntesen kan generera uppgifter som "Förklara fotosyntesens process steg för steg" eller "Jämför fotosyntesen med cellandningen".';
      case 'Fylla i luckan':
        return 'En text om vattnets kretslopp kan generera frågor som "Vatten avdunstar från hav och sjöar och bildar ______ (moln) i atmosfären."';

      default:
        return 'Exempel kommer snart...';
    }
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async e => {
        const arrayBuffer = e.target.result;
        try {
          const result = await mammoth.extractRawText({ arrayBuffer });
          if (subjectInputRef.current) {
            subjectInputRef.current.value = result.value.slice(0, maxInputLength);
          }
        } catch (error) {
          console.error('Error processing Word file:', error);
          // You might want to show an error message to the user here
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer, padding: { xs: '0px', md: undefined } }}>
        <Typography variant='h1' sx={styles.header}>
          Textlabbet
        </Typography>

        <Box
          sx={{
            ...styles.darkBorder,
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
            border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
            borderRadius: { xs: '0px', md: '8px' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
            <Box>
              <Typography
                variant='h4'
                sx={{
                  ...styles.header,
                  ...styles.headerAboveBox,
                  marginBottom: 2,
                  marginTop: 0,
                  fontSize: '20px',
                }}
              >
                1. Skriv in din text
              </Typography>
              <TextField
                multiline
                fullWidth
                rows={6}
                inputRef={subjectInputRef}
                variant='outlined'
                onChange={handleInputChange}
                placeholder='Skriv in din text, uppgift, läxa, dikt, uppsats eller artikel här.'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IdeaGenerator
                        user={user}
                        onIdeaGenerated={handleIdeaGenerated}
                        systemMessage="ge mig en idé på en slumpmässig textmassa på 4-5 meningar. Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
                        position='top'
                        size='small'
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{ marginTop: '6px' }}
              >
                <Box display='flex' alignItems='center'>
                  <Suggestions
                    listType='textManagerIdeas'
                    maxSuggestions={6}
                    onSelect={handleSelect}
                  />
                </Box>
                <Typography variant='caption'>
                  {inputLength}/{maxInputLength}
                </Typography>
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                <label htmlFor='word-file-upload'>
                  <Input
                    id='word-file-upload'
                    type='file'
                    sx={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept='.docx'
                  />
                  <Button
                    variant='outlined'
                    component='span'
                    startIcon={<WordIcon />}
                    sx={{ borderRadius: '20px' }}
                  >
                    Ladda upp Word-fil
                  </Button>
                </label>
              </Box>
            </Box>

            <Typography
              variant='h4'
              sx={{
                ...styles.header,
                ...styles.headerAboveBox,
                marginBottom: 0,
                marginTop: 3,
                fontSize: '20px',
              }}
            >
              {actionText}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'left',
                gap: 1,
                mb: 5,
                mt: 0,
              }}
            >
              {(showAllButtons
                ? buttons
                : buttons.filter(button => visibleButtons.includes(button.label))
              ).map(({ label, icon: Icon, instruction }) => (
                <Button
                  key={label}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  startIcon={isProcessing && activeButton === label ? <StopIcon /> : <Icon />}
                  onClick={() => handleButtonClick(instruction, label)}
                  disabled={isProcessing && activeButton !== label}
                  sx={{
                    borderRadius: theme.palette.mode === 'dark' ? undefined : '25px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  {label}
                </Button>
              ))}
              {!showAllButtons && (
                <Button
                  variant='outlined'
                  onClick={() => setShowAllButtons(true)}
                  sx={{
                    borderRadius: theme.palette.mode === 'dark' ? undefined : '25px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Visa alla funktioner
                </Button>
              )}
            </Box>

            <Box sx={{}}>
              <Typography
                variant='h4'
                sx={{
                  ...styles.header,
                  ...styles.headerAboveBox,
                  marginBottom: 2,
                  marginTop: 0,
                  fontSize: '20px',
                }}
              >
                3. Resultat
              </Typography>
              <Box
                ref={outputBoxRef}
                sx={{
                  borderRadius: '15px',
                  backgroundColor: theme.palette.mode === 'dark' ? '#1c262f' : '#fcfcfc',
                  border: theme.palette.mode === 'dark' ? '1px solid #3b4651' : '1px solid #ededed',
                  p: 2,
                  minHeight: '300px',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  '& p': { my: 1 },
                  scrollBehavior: 'smooth',
                  position: 'relative',
                }}
              >
                <Tooltip title='Kopiera'>
                  <IconButton
                    onClick={handleCopy}
                    sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
                  >
                    {isCopied ? <CheckIcon /> : <ContentCopyIcon />}
                  </IconButton>
                </Tooltip>

                {isProcessing && !outputText && (
                  <Box sx={{ display: 'flex', alignItems: 'left', height: '100%' }}>
                    <CircularProgress size={16} />
                  </Box>
                )}
                {!isProcessing && !outputText && (
                  <Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    Din behandlade text hamnar här...
                  </Typography>
                )}

                <div dangerouslySetInnerHTML={{ __html: outputText }} />
              </Box>

              {/* New export button */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<FileDownloadIcon />}
                  onClick={handleExportClick}
                  disabled={!outputText}
                >
                  Exportera
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
                  <MenuItem onClick={exportToPDF}>PDF</MenuItem>
                  <MenuItem onClick={exportToWord}>Word</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>

        <Typography variant='h4' sx={{ ...styles.header, marginBottom: 2, marginTop: 6 }}>
          Upptäck kraften i Textlabbet
        </Typography>
        <Box
          sx={{
            ...styles.darkBorder,
            padding: '30px',
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
            border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
            borderRadius: { xs: '0px', md: '8px' },
          }}
        >
          <Typography variant='body1' paragraph sx={{ mb: 4 }}>
            Förvandla dina texter med våra innovativa verktyg. Oavsett om du är student,
            professionell skribent eller kreativ själ, har Textlabbet något för dig.
          </Typography>

          <Grid container spacing={3}>
            {buttons.map(({ label, icon: Icon, instruction }) => (
              <Grid item xs={12} sm={6} md={4} key={label}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    height: '100%',
                    display: 'flex',
                    borderRadius: '10px',
                    flexDirection: 'column',
                    transition: 'all 0.3s',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: 3 },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Icon sx={{ mr: 1, color: theme.palette.secondary.main }} />
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                      {label}
                    </Typography>
                  </Box>
                  <Typography variant='body2' sx={{ mb: 2, flexGrow: 1 }}>
                    {instruction}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ fontStyle: 'italic', color: theme.palette.text.secondary }}
                  >
                    Exempel: {getExampleForFunction(label)}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TextManager;
