import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ExploreIcon from '@mui/icons-material/Explore';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { collection, query, where, orderBy, limit, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const TeacherList = ({ teachers, onDeleteTeacher, isDark, toggleSidebar, isMobile }) => {
  const [teacherMenuAnchorEl, setTeacherMenuAnchorEl] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [groupedTeachers, setGroupedTeachers] = useState({
    today: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    aWhileAgo: [],
  });

  useEffect(() => {
    const categorizeTeachers = teachersWithConversations => {
      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(yesterday.getDate() - 1);
      const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

      const grouped = teachersWithConversations.reduce(
        (acc, teacher) => {
          if (!teacher.lastConversation) {
            acc.aWhileAgo.push(teacher);
          } else {
            const conversationDate = teacher.lastConversation.createdAt.toDate();
            if (conversationDate >= new Date(now.setHours(0, 0, 0, 0))) {
              acc.today.push(teacher);
            } else if (conversationDate >= new Date(yesterday.setHours(0, 0, 0, 0))) {
              acc.yesterday.push(teacher);
            } else if (conversationDate >= oneWeekAgo) {
              acc.thisWeek.push(teacher);
            } else if (conversationDate >= oneMonthAgo) {
              acc.thisMonth.push(teacher);
            } else {
              acc.aWhileAgo.push(teacher);
            }
          }
          return acc;
        },
        { today: [], yesterday: [], thisWeek: [], thisMonth: [], aWhileAgo: [] }
      );

      // Sortera lärare inom varje kategori
      for (let category in grouped) {
        grouped[category].sort((a, b) => {
          if (!a.lastConversation && !b.lastConversation) return 0;
          if (!a.lastConversation) return 1;
          if (!b.lastConversation) return -1;
          return b.lastConversation.createdAt.toDate() - a.lastConversation.createdAt.toDate();
        });
      }

      setGroupedTeachers(grouped);
    };

    const conversationsRef = collection(db, 'conversations');
    const q = query(conversationsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, async querySnapshot => {
      const latestConversationsMap = new Map();

      querySnapshot.forEach(doc => {
        const data = doc.data();
        const teacherId = data.conversationType;
        if (!latestConversationsMap.has(teacherId)) {
          latestConversationsMap.set(teacherId, data);
        }
      });

      const teachersWithLastConversation = teachers.map(teacher => ({
        ...teacher,
        lastConversation: latestConversationsMap.get(teacher.id) || null,
      }));

      categorizeTeachers(teachersWithLastConversation);
    });

    const fetchInitialConversations = async () => {
      const teachersWithLastConversation = await Promise.all(
        teachers.map(async teacher => {
          const conversationsRef = collection(db, 'conversations');
          const q = query(
            conversationsRef,
            where('conversationType', '==', teacher.id),
            orderBy('createdAt', 'desc'),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          let lastConversation = null;
          querySnapshot.forEach(doc => {
            lastConversation = doc.data();
          });
          return { ...teacher, lastConversation };
        })
      );

      categorizeTeachers(teachersWithLastConversation);
    };

    fetchInitialConversations();

    return () => unsubscribe();
  }, [teachers]);

  const handleMoreClick = (event, teacherId) => {
    event.preventDefault();
    event.stopPropagation();
    setTeacherMenuAnchorEl(event.currentTarget);
    setSelectedTeacherId(teacherId);
  };

  const handleMenuClose = () => {
    setTeacherMenuAnchorEl(null);
    setSelectedTeacherId(null);
  };

  const removeTeacherFromLocalState = teacherId => {
    setGroupedTeachers(prevGroupedTeachers => {
      const newGroupedTeachers = { ...prevGroupedTeachers };
      for (const category in newGroupedTeachers) {
        newGroupedTeachers[category] = newGroupedTeachers[category].filter(
          teacher => teacher.id !== teacherId
        );
      }
      return newGroupedTeachers;
    });
  };

  const handleDelete = () => {
    removeTeacherFromLocalState(selectedTeacherId);
    onDeleteTeacher(selectedTeacherId);
    handleMenuClose();
  };

  const handleEdit = () => {
    navigate(`/app/larare/${selectedTeacherId}`);
    handleMenuClose();
  };

  const getLanguageText = languageToLearn => {
    const languageMap = {
      spanish: 'Spansklärare',
      french: 'Fransklärare',
      german: 'Tysklärare',
    };
    return languageMap[languageToLearn] || '';
  };

  const renderTeacherGroup = (groupTitle, teachers) => {
    if (teachers.length === 0) return null;

    return (
      <>
        <Typography
          variant='subtitle1'
          sx={{
            ...styles.grayText,
            fontWeight: '500',
            fontSize: '13px',
            background: isDark ? '#192734' : 'white',
            paddingTop: '15px',
            paddingLeft: '15px',
          }}
        >
          {groupTitle}
        </Typography>
        <List sx={{ py: 0, margin: '8px' }}>
          {teachers.map(teacher => (
            <ListItem
              key={teacher.id}
              onClick={() => {
                if (isMobile) toggleSidebar();
                navigate(`/app/chat/${teacher.id}`);
              }}
              sx={{
                py: 0,
                paddingTop: '3px',
                paddingBottom: '3px',
                '&:not(:last-child)': {
                  mb: '3px',
                },
                borderRadius: '7px',
                '&:hover': {
                  borderRadius: '7px !important',
                  cursor: 'pointer',
                  '& .MuiListItemIcon-root': {
                    color: '#8b5cf6',
                  },
                  '& .MuiTypography-root': {
                    color: isDark === true ? '#fff' : '#000',
                  },
                },

                '&:hover .more-icon, .more-icon.visible': {
                  opacity: 1,
                  visibility: 'visible',
                },
              }}
              secondaryAction={
                <IconButton
                  edge='end'
                  onClick={event => handleMoreClick(event, teacher.id)}
                  className={`more-icon ${teacherMenuAnchorEl ? 'visible' : ''}`}
                  sx={{
                    opacity: 0,
                    visibility: 'hidden',
                    transition: 'opacity 0.2s, visibility 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <MoreHorizIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              }
            >
              {teacher.imageUrl && (
                <ListItemAvatar sx={{ minWidth: 'auto', mr: 1 }}>
                  <Avatar
                    src={teacher.imageUrl}
                    alt={teacher.characterName}
                    sx={{ width: '25px', height: '25px', mr: 0 }}
                  />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  teacher.category === 'Språk' && teacher.languageToLearn
                    ? getLanguageText(teacher.languageToLearn)
                    : teacher.characterName
                }
                primaryTypographyProps={{
                  color: isDark ? '#b0b4b8' : '#4a5568',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                sx={{
                  color: isDark ? '#b0b4b8' : '#4a5568',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  '& .MuiTypography-root': {
                    color: isDark ? '#b0b4b8' : '#4a5568',
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <>
      <List sx={{ py: 0, margin: '8px' }}>
        <ListItem
          component={Link}
          to='/app/larare'
          onClick={() => {
            if (isMobile) toggleSidebar();
          }}
          sx={{
            py: 0,
            paddingTop: '3px',
            paddingBottom: '3px',
            '&:not(:last-child)': {
              mb: '3px',
            },
            borderRadius: '7px',
            '&:hover': {
              borderRadius: '7px !important',
              cursor: 'pointer',
              '& .MuiListItemIcon-root': {
                color: '#8b5cf6',
              },
              '& .MuiTypography-root': {
                color: isDark === true ? '#fff' : '#000',
              },
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px' }}>
            <AddIcon sx={{ color: isDark ? '#8b5cf6' : '#8b5cf6', fontSize: '24px' }} />
          </ListItemIcon>
          <ListItemText
            primary='Skapa karaktär'
            sx={{
              color: isDark ? '#b0b4b8' : '#4a5568',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '500',
              '& .MuiTypography-root': {
                color: isDark ? '#b0b4b8' : '#4a5568',
              },
            }}
            primaryTypographyProps={{
              color: isDark ? '#b0b4b8' : '#4a5568',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '500',
            }}
          />
        </ListItem>

        <ListItem
          component={Link}
          to='/app/personal'
          onClick={() => {
            if (isMobile) toggleSidebar();
          }}
          sx={{
            py: 0,
            paddingTop: '3px',
            paddingBottom: '3px',
            '&:not(:last-child)': {
              mb: '3px',
            },
            borderRadius: '7px',
            '&:hover': {
              borderRadius: '7px !important',
              cursor: 'pointer',
              '& .MuiListItemIcon-root': {
                color: '#8b5cf6',
              },
              '& .MuiTypography-root': {
                color: isDark === true ? '#fff' : '#000',
              },
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '28px', paddingRight: '8px' }}>
            <ExploreIcon sx={{ color: isDark ? '#8b5cf6' : '#8b5cf6', fontSize: '24px' }} />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: isDark ? '#b0b4b8' : '#4a5568',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '500',
              '& .MuiTypography-root': {
                color: isDark ? '#b0b4b8' : '#4a5568',
              },
            }}
            primary='Upptäck'
            primaryTypographyProps={{
              color: isDark ? '#b0b4b8' : '#4a5568',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '500',
            }}
          />
        </ListItem>
      </List>
      {/*
      <Typography
        variant='subtitle1'
        sx={{
          fontWeight: '500',
          fontSize: '15px',
          background: isDark ? '#192734' : 'white',
          paddingTop: '15px',
          paddingLeft: '15px',
          marginTop: '10px',
          color: isDark ? 'white' : 'black',
        }}
      >
        Chattar
      </Typography>
*/}
      {renderTeacherGroup('I dag', groupedTeachers.today)}
      {renderTeacherGroup('I går', groupedTeachers.yesterday)}
      {renderTeacherGroup('Denna vecka', groupedTeachers.thisWeek)}
      {renderTeacherGroup('Denna månad', groupedTeachers.thisMonth)}
      {renderTeacherGroup('För ett tag sedan', groupedTeachers.aWhileAgo)}

      <Menu
        anchorEl={teacherMenuAnchorEl}
        open={Boolean(teacherMenuAnchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            ...styles.roundMenu,
            borderRadius: '8px',
            '& ul': {
              border: theme.palette.mode === 'dark' ? '1px solid #35414db2' : '1px solid #eee',
              padding: '0px',
              paddingTop: '10px',
              paddingBottom: '10px',
              borderRadius: '8px',
            },
          },
        }}
      >
        <MenuItem onClick={handleEdit} sx={styles.menuItemStyle}>
          <EditIcon fontSize='small' style={{ marginRight: 8 }} />
          Redigera
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={styles.menuItemStyle}>
          <DeleteIcon fontSize='small' style={{ marginRight: 8 }} />
          Radera
        </MenuItem>
      </Menu>
    </>
  );
};

export default TeacherList;
