// functions.js
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from './firebase'; // Se till att du importerar din Firebase-instans korrekt
import { formatDistanceToNow, format } from 'date-fns';
import { sv } from 'date-fns/locale';
import Logger from './Logger';
import ChatIcon from '@mui/icons-material/Chat';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LanguageIcon from '@mui/icons-material/Language';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AssessmentIcon from '@mui/icons-material/Assessment';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import StarIcon from '@mui/icons-material/Star';
import CreateIcon from '@mui/icons-material/Create';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Helmet } from 'react-helmet-async';
// **Newly Added Icon Imports**
import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TranslateIcon from '@mui/icons-material/Translate';
import BuildIcon from '@mui/icons-material/Build';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import GradeIcon from '@mui/icons-material/Grade';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SaveIcon from '@mui/icons-material/Save';
import MenuBookIcon from '@mui/icons-material/MenuBook'; // **Added Import**

import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TransformIcon from '@mui/icons-material/Transform';
import TuneIcon from '@mui/icons-material/Tune';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CategoryIcon from '@mui/icons-material/Category';

/**
 * Decrease tokens by 50 for a specific user.
 * @param {string} userId - The user ID.
 * @returns {Promise<void>}
 */
export const flagUrls = {
  Spanish:
    'https://firebasestorage.googleapis.com/v0/b/personae-2a07b.appspot.com/o/languages%2Fspanish.png?alt=media&token=f00096d3-7d17-40a9-a150-cfacd020e8de',
  French:
    'https://firebasestorage.googleapis.com/v0/b/personae-2a07b.appspot.com/o/languages%2Ffrench.png?alt=media&token=33db1b5d-0bed-411e-8438-430f5b866e50',
  German:
    'https://firebasestorage.googleapis.com/v0/b/personae-2a07b.appspot.com/o/languages%2Fgerman2.png?alt=media&token=c4e93701-918f-44af-85bc-5ee35dd5d935',
  Swedish:
    'https://firebasestorage.googleapis.com/v0/b/personae-2a07b.appspot.com/o/languages%2Fswedish.png?alt=media&token=8a880d8f-8945-4064-9440-200999a568e1',
  English:
    'https://firebasestorage.googleapis.com/v0/b/personae-2a07b.appspot.com/o/languages%2Fenglish.png?alt=media&token=e6a8424c-6809-4566-aa03-732705d6c031',
};
export const formatUpvotes = upvotes => {
  if (upvotes == null) return '0'; // Handle undefined or null

  const num = parseInt(upvotes, 10);
  if (isNaN(num)) return '0'; // Handle non-numeric strings

  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return num.toString();
};

export const sanitizeInput = input => {
  if (typeof input !== 'string') {
    return '';
  }

  // Remove HTML tags
  let sanitized = input.replace(/<[^>]*>/g, '');

  // Encode special characters, excluding single quotes
  sanitized = sanitized
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');

  return sanitized;
};

export async function decreaseTokens(userId) {
  const userDocRef = doc(db, 'users', userId);

  try {
    await updateDoc(userDocRef, {
      tokens: increment(-50),
    });
    Logger.log('Tokens successfully decreased by 50.');
  } catch (error) {
    console.error('Error decreasing tokens: ', error);
  }
}

export async function updateUserTokens(userId, tokens) {
  const isGuest = userId.startsWith('guest-');

  const collectionName = 'users';
  const userDocRef = doc(db, collectionName, userId);
  try {
    await updateDoc(userDocRef, {
      tokens: increment(tokens),
    });
    Logger.log(
      'Tokens successfully increased by ',
      tokens,
      ' for user ',
      userId,
      ' which is guest ',
      isGuest
    );
  } catch (error) {
    console.error('Error updating tokens: ', error);
  }
}

export const createUrlSlug = name => {
  return name
    .toLowerCase()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const formatDate = timestamp => {
  let date;

  if (timestamp && typeof timestamp.toDate === 'function') {
    // Firestore Timestamp
    date = timestamp.toDate();
  } else if (typeof timestamp === 'number') {
    // Unix timestamp in milliseconds (like Date.now())
    date = new Date(timestamp);
  } else {
    console.error('Unexpected timestamp format:', timestamp);
    return 'Okänt datum';
  }

  if (isNaN(date.getTime())) {
    console.error('Invalid date:', timestamp);
    return 'Okänt datum';
  }

  const now = new Date();
  const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    const distance = formatDistanceToNow(date, { addSuffix: true, locale: sv });
    // Custom shortening logic
    return distance
      .replace('ungefär ', '')
      .replace(' sekunder', ' sek')
      .replace(' minuter', ' min')
      .replace(' timmar', ' tim')
      .replace(' timme', ' timme')
      .replace('mindre än en minut', '< 1 min');
  } else if (diffInDays === 1) {
    return 'Igår';
  } else if (diffInDays < 7) {
    return `${diffInDays} dagar sedan`;
  } else {
    return format(date, 'd MMM yyyy', { locale: sv });
  }
};

export const features = [
  {
    title: 'Lär dig språk med AI',
    description: 'Lär dig vilket språk du vill genom interaktiva samtal och övningar.',
    icon: <LanguageIcon />,
    video: { light: '/img/lang-l.mp4', dark: '/img/lang-d.mp4' },
    details: [
      {
        text: 'Interaktiva dialoger: Delta i simulerade konversationer som speglar vardagliga situationer, vilket hjälper dig att bli mer flytande och självsäker i ditt valda språk.',
        icon: <RecordVoiceOverIcon />,
      },
      {
        text: 'Grammatikövningar: Arbeta med skräddarsydda grammatikövningar för att förbättra din förståelse av språkliga strukturer och öka din språkliga precision.',
        icon: <GTranslateIcon />,
      },
      {
        text: 'Vokabulärträning: Utöka ditt ordförråd genom att lära dig nya ord och fraser i kontext, vilket underlättar memorering och korrekt användning.',
        icon: <MenuBookIcon />,
      },
      {
        text: 'Nivåbedömning: Låt AI utvärdera din nuvarande språknivå och ge rekommendationer för vidare utveckling, så att du kan fokusera på områden som behöver förbättras.',
        icon: <GradeIcon />,
      },
    ],
  },
  {
    title: 'Skapa kurser med AI',
    description: 'Skapa skräddarsydda kurser med AI.',
    icon: <SchoolIcon />,
    video: { light: '/img/kurser-l.mp4', dark: '/img/kurser-d.mp4' },
    details: [
      {
        text: 'AI-drivna kurser: Skapa omfattande kurser med hjälp av AI, inklusive delkurser och kapitel, anpassade efter dina inlärningsbehov och mål.',
        icon: <AutoFixHighIcon />,
      },
      {
        text: 'Interaktiva lektioner: Gå igenom lektioner i din egen takt, med möjlighet att generera quiz och flashcards för varje lektion för att förstärka ditt lärande.',
        icon: <MenuBookIcon />,
      },
      {
        text: 'AI-assisterad fördjupning: Chatta med en AI-lärare om specifika kapitel för att få förtydliganden, exempel och djupare insikter i ämnet.',
        icon: <ChatIcon />,
      },
      {
        text: 'Framstegsövervakning: Markera lektioner som slutförda och följ din progression genom kursen, vilket ger dig en tydlig överblick över din inlärningsresa.',
        icon: <CheckCircleOutlineIcon />,
      },
    ],
  },
  {
    title: 'Quiz',
    description: 'Generera quiz baserat på ditt studiematerial.',
    icon: <QuizIcon />,
    video: { light: '/img/q-l.mp4', dark: '/img/q-d.mp4' },
    //img: { light: '/img/l.png', dark: '/img/l.png' },
    details: [
      {
        text: 'AI-genererade frågor: Använd artificiell intelligens för att automatiskt skapa quiz-frågor från vilket ämne eller textmaterial som helst, vilket underlättar din inlärningsprocess.',
        icon: <QuestionAnswerIcon />,
      },
      {
        text: 'Dokumentbaserat: Ladda upp dina Word- eller PDF-dokument för att omvandla dem till interaktiva quiz, vilket gör det enkelt att studera dina egna material.',
        icon: <MenuBookIcon />,
      },
      {
        text: 'Videomaterial: Omvandla YouTube-föreläsningar till quiz genom att extrahera nyckelinformation och frågor, vilket hjälper dig att bättre förstå och komma ihåg innehållet.',
        icon: <PlayCircleOutlineIcon />,
      },
      {
        text: 'Anpassningsbart: Skräddarsy dina quiz genom att välja specifika frågetyper, justera svårighetsgraden och bestämma antalet frågor för att matcha dina inlärningsbehov.',
        icon: <SettingsIcon />,
      },
    ],
  },
  {
    title: 'Textlabbet',
    description: 'Förvandla dina texter med AI.',
    icon: <CreateIcon />,
    video: { light: '/img/t-l.mp4', dark: '/img/t-d.mp4' },
    details: [
      {
        text: 'Textanalys: Använd AI för att summera långa texter, genomföra djupgående analyser och tillhandahålla konstruktiv kritik, vilket hjälper dig att förbättra ditt skrivande.',
        icon: <FindInPageIcon />,
      },
      {
        text: 'Omstrukturering: Förbättra flödet och läsbarheten i dina texter genom att modernisera språket och omorganisera innehållet på ett logiskt och sammanhängande sätt.',
        icon: <AutoFixHighIcon />,
      },
      {
        text: 'Redigering: Låt AI assistera dig med att korrigera grammatiska fel, förbättra  stilistiken och optimera textens struktur för att uppnå professionell kvalitet.',
        icon: <SpellcheckIcon />,
      },
      {
        text: 'Textlagring: Håll ordning på dina dokument genom att spara och organisera dina redigerade texter på ett lättillgängligt sätt för framtida referens.',
        icon: <SaveIcon />,
      },
    ],
  },

  {
    title: 'Flashcards',
    description: 'Generera flashcards baserat på ditt studiematerial',
    icon: <ViewAgendaIcon />,
    video: { light: '/img/f-l.mp4', dark: '/img/f-d.mp4' },
    details: [
      {
        text: 'AI-genererade kort: Använd AI-teknik för att automatiskt skapa effektiva flashcards från valfri text eller ämne, vilket förbättrar din inlärning och minnesförmåga.',
        icon: <AutoAwesomeIcon />,
      },
      {
        text: 'Filkompatibilitet: Ladda upp dina Word- eller PDF-dokument för att omvandla dem till användbara flashcards, vilket gör det enkelt att studera direkt från dina egna material.',
        icon: <InsertDriveFileIcon />,
      },
      {
        text: 'Videobaserat: Extrahera nyckelbegrepp och information från YouTube-föreläsningar för att skapa flashcards, vilket hjälper dig att bättre att komma ihåg information.',
        icon: <OndemandVideoIcon />,
      },
      {
        text: 'Effektiv inlärning: Implementera principen om distribuerad repetition för att förbättra din minnesretention och optimera din inlärningsprocess.',
        icon: <TrendingUpIcon />,
      },
    ],
  },
  {
    title: 'AI-lärare',
    description: 'Chatta med anpassade AI-karaktärer och historiska figurer.',
    icon: <SchoolIcon />,
    video: { light: '/img/k-l.mp4', dark: '/img/k-d.mp4' },
    details: [
      {
        text: 'Anpassade lärare: Skapa din egen AI-karaktär genom att ange specifika instruktioner och preferenser, vilket ger dig en skräddarsydd inlärningsupplevelse.',
        icon: <BuildIcon />,
      },
      {
        text: 'Inkluderande lärande: Välj bland en mängd olika AI-lärare anpassade för olika inlärningsstilar och behov, vilket säkerställer att du får den mest effektiva undervisningen.',
        icon: <AccessibilityNewIcon />,
      },
      {
        text: 'Historiska dialoger: Engagera dig i konversationer med virtuella representationer av kända tänkare och vetenskapsmän för att fördjupa din förståelse för deras idéer och bidrag.',
        icon: <HistoryEduIcon />,
      },
    ],
  },

  {
    title: 'Högskoleprovet',
    description: 'Träna på gamla och AI-genererade högskoleprov.',
    icon: <MenuBookIcon />,
    video: { light: '/img/h-l.mp4', dark: '/img/h-d.mp4' },
    details: [
      {
        text: 'Provgenerering: Generera skräddarsydda prov som täcker alla delar av högskoleprovet, inklusive ORD, LÄS, MEK, XYZ och ELF, för att förbereda dig  inför testet.',
        icon: <CreateIcon />,
      },
      {
        text: 'AI-assistans: Dra nytta av AI-genererade förklaringar och lösningar för varje fråga, vilket hjälper dig att förstå koncepten bakom svaren och förbättra din prestation.',
        icon: <LiveHelpIcon />,
      },
      {
        text: 'Övervaka  framsteg: Övervaka dina framsteg över tid med detaljerade rapporter och analyser, vilket hjälper dig att identifiera styrkor och svagheter.',
        icon: <TrendingUpIcon />,
      },
      {
        text: 'Anpassad träning: Få rekommendationer på vilka områden du bör fokusera på baserat på dina resultat, så att du kan effektivisera din studietid.',
        icon: <FitnessCenterIcon />,
      },
      {
        text: 'Gamla högskoleprov: Träna även på gamla högskoleprov för att veta ungefär vad som väntar dig när det är dags för provdagen.',
        icon: <GradeIcon />,
      },
    ],
  },

  {
    title: 'Fråga AI',
    description: 'Få djupgående förklaringar på komplexa ämnen.',
    icon: <ChatIcon />,
    video: { light: '/img/fai-l.mp4', dark: '/img/fai-d.mp4' },
    details: [
      {
        text: 'Konceptförklaring: Låt AI bryta ner komplexa begrepp i enklare, mer hanterbara delar, vilket gör det lättare för dig att förstå och tillämpa dem.',
        icon: <EmojiObjectsIcon />,
      },
      {
        text: 'Visualisering: Använd AI för att skapa visuella representationer av abstrakta idéer och koncept, vilket underlättar förståelsen och minnesretentionen.',
        icon: <VisibilityIcon />,
      },
      {
        text: 'Läxhjälp: Få personlig assistans med dina uppgifter och förbättra din studieteknik genom AI-drivna rekommendationer och tips.',
        icon: <SchoolIcon />,
      },
      {
        text: 'Anpassade instruktioner: Utforma egna AI-prompts och frågor för att få skräddarsydd hjälp som passar dina specifika inlärningsbehov.',
        icon: <BuildIcon />,
      },
    ],
  },
  {
    title: 'AI-debatt',
    description: 'Simulera en debatt mellan dig själv och en historisk person.',
    icon: <QuestionAnswerIcon />,
    video: { light: '/img/debatt-l.mp4', dark: '/img/debatt-d.mp4' },
    details: [
      {
        text: 'Argumentationsteknik: Förbättra dina argumentationsförmågor genom engagerande och interaktiva debatter, vilket hjälper dig att uttrycka dina idéer mer effektivt och övertygande.',
        icon: <ForumIcon />,
      },
      {
        text: 'Historiska simuleringar: Delta i virtuella debatter med framstående historiska figurer som Sigmund Freud och Carl Jung, och få insikt i deras tankesätt och teorier.',
        icon: <HistoryEduIcon />,
      },
      {
        text: 'Faktakoll: Säkerställ korrekthet i dina debatter genom att verifiera argument och påståenden direkt under diskussionen.',
        icon: <VerifiedUserIcon />,
      },
      {
        text: 'Fördjupad förståelse: Få en djupare insikt i komplexa ämnen genom att analysera och diskutera dem ur flera olika perspektiv.',
        icon: <PsychologyIcon />,
      },
    ],
  },
  {
    title: 'Vi stödjer mängder av olika AI-modeller',
    description: 'Generera studiematerial med olika Large Language Models (LLM)',
    video: { light: '/img/llm-l.mp4', dark: '/img/llm-d.mp4' },
    icon: <SchoolIcon />,
    models: [
      {
        name: 'Studera.AI',
        description:
          'Vår egenutvecklade AI-modell som är specialdesignad för att erbjuda en anpassad och effektiv inlärningsupplevelse, skräddarsydd efter dina behov.',
      },
      {
        name: 'Chat GPT 4o-mini',
        description:
          'En lättviktig version av GPT-4 som erbjuder snabba och effektiva svar, idealisk för enklare förfrågningar och när du behöver information snabbt.',
      },
      {
        name: 'Chat GPT 4o',
        description:
          'Den fullständiga GPT-4-modellen som levererar avancerade och detaljerade svar, perfekt för komplexa frågor och djupgående analyser.',
      },
      {
        name: 'Claude 3.5 Sonnet',
        description:
          'En sofistikerad AI-modell designad för att erbjuda djupare förståelse och insikter, vilket förbättrar din förmåga att hantera komplex information.',
      },
      {
        name: 'Claude 3 Sonnet',
        description:
          'En effektiv AI-modell som är optimerad för snabb informationshantering och bearbetning, vilket underlättar din inlärningsprocess.',
      },
      {
        name: 'Gemini Pro',
        description:
          'En AI-modell utrustad med avancerade analysfunktioner, idealisk för djupgående forskning och komplexa dataanalyser.',
      },
      {
        name: 'Mistral Large',
        description:
          'En högpresterande AI-modell designad för att hantera komplexa problemlösningar och stora datamängder, vilket hjälper dig att lösa avancerade uppgifter.',
      },
    ],
  },

  {
    title: 'AI-detektor',
    description: 'Analysera text för att kontrollera huruvida den är AI-genererad eller ej.',
    icon: <AssessmentIcon />,
    video: { light: '/img/aid-l.mp4', dark: '/img/aid-d.mp4' },
    details: [
      {
        text: 'Lingvistisk analys: Använd avancerad språkteknologi för att identifiera och analysera lingvistiska mönster som ofta förekommer i AI-genererad text, vilket hjälper dig att avgöra textens ursprung.',
        icon: <FindInPageIcon />,
      },
      {
        text: 'Stilometrisk profilering: Utför en detaljerad analys av författarens skrivstil för att upptäcka eventuella avvikelser eller inkonsekvenser som kan indikera AI-generering.',
        icon: <FingerprintIcon />,
      },
      {
        text: 'Diskursanalys: Analysera strukturen och flödet av argument och idéer för att avgöra om texten följer typiska mänskliga mönster eller om den är genererad av AI.',
        icon: <CompareArrowsIcon />,
      },
    ],
  },
  {
    title: 'Generera text med AI',
    description: 'Använd vår textgenerator för att skriva vad du vill.',
    icon: <KeyboardIcon />,
    video: { light: '/img/g-l.mp4', dark: '/img/g-d.mp4' },
    details: [
      {
        text: 'Generera text: Använd vår kraftfulla AI för att snabbt skapa välskrivna textstycken om vilket ämne som helst, vilket sparar tid och ansträngning.',
        icon: <CreateIcon />,
      },
      {
        text: 'Textanpassning: Anpassa den genererade texten efter dina behov genom att förkorta, förlänga eller omstrukturera innehållet.',
        icon: <TransformIcon />,
      },
      {
        text: 'Ton och stil: Justera textens ton och stil genom att välja mellan formellt, informellt, akademiskt eller kreativt språkbruk för att matcha din önskade kommunikationsstil.',
        icon: <TuneIcon />,
      },
      {
        text: 'Brainstorming: Utnyttja AI för att generera nya idéer och ämnen, baserat på befintlig text, vilket underlättar kreativt tänkande och innehållsutveckling.',
        icon: <LightbulbIcon />,
      },
      {
        text: 'Spara dina texter: Håll dina genererade texter organiserade genom att spara dem i vår plattform, vilket gör det enkelt att återanvända eller referera till dem i framtiden.',
        icon: <SaveIcon />,
      },
    ],
  },
  {
    title: 'Spara  i din egen anteckningsbok',
    description: 'Spara frågor från högskoleprov, svar från en Chatbot eller en quiz-fråga',
    icon: <BookmarkIcon />,
    video: { light: '/img/s-l.mp4', dark: '/img/s-d.mp4' },
    details: [
      {
        text: 'Personlig lagring: Håll all din studierelaterade information samlad genom att spara anteckningar och material från olika sektioner på ett ställe, vilket underlättar åtkomst och organisering.',
        icon: <LibraryBooksIcon />,
      },
      {
        text: 'Filformat: Exportera dina sparade anteckningar och material i olika format såsom CSV, PDF eller Word, vilket ger dig flexibilitet att använda dem på det sätt som passar dig bäst.',
        icon: <FileDownloadIcon />,
      },
      {
        text: 'Automatisk kategorisering: Låt systemet automatiskt organisera dina sparade material baserat på quiz, flashcards eller högskoleprov så att du snabbt kan hitta det du behöver.',
        icon: <CategoryIcon />,
      },
    ],
  },
];

// export async function updateUserTokens(userId, tokens) {
//     const userDocRef = doc(db, "users", userId);

//     try {
//         await updateDoc(userDocRef, {
//             tokens: increment(tokens)
//         });
// Logger.log("Tokens successfully increased by ", tokens, " for user ", userId);
//     } catch (error) {
//         console.error("Error decreasing tokens: ", error);
//     }
// }
