import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WikipediaIcon from './WikipediaIcon';
import WordIcon from './Icons/WordIcon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Grading as GradingIcon } from '@mui/icons-material';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import GavelIcon from '@mui/icons-material/Gavel';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuizIcon from '@mui/icons-material/Quiz';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { TableChart as TableChartIcon } from '@mui/icons-material';
import MenuOpenIcon from '@mui/icons-material/MenuBook';
import ClassIcon from '@mui/icons-material/Class';
import TopicIcon from '@mui/icons-material/Subject';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import Person3Icon from '@mui/icons-material/Person3';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import BarChartIcon from '@mui/icons-material/BarChart';
import TranslateIcon from '@mui/icons-material/Translate';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';

import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ShortcutBox from './ShortcutBox'; // Adjust the path as necessary

const DashboardToolsShortcuts = ({ role, resetCategory, theme }) => {
  const [selectedCategory, setSelectedCategory] = useState('Alla');

  useEffect(() => {
    setSelectedCategory('Alla');
  }, [role, resetCategory]);

  const shortcuts = [
    {
      icon: <GradingIcon />,
      title: 'Betygsätt en uppsats',
      subtitle: 'Betygsätt uppsats baserat på vald bedömningsmatris.',
      path: '/app/uppsats/ny',
      roles: ['teacher'],
      category: 'Bedömning',
      label: 'Populär',
    },
    {
      icon: <TableChartIcon />,
      title: 'Skapa bedömningsmatris',
      subtitle: 'Skapa avancerade bedömningsmatriser.',
      path: '/app/matris/ny',
      roles: ['teacher'],
      category: 'Bedömning',
      label: 'Nytt',
    },
    {
      icon: <ClassIcon />,
      title: 'Skapa en lektionsplan',
      subtitle: 'Generera en strukturerad lektionsplan för ditt ämne',
      path: '/app/fraga-ai?prompt=generera-lektionsplan',
      roles: ['teacher'],
      category: 'Lektionsplanering',
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fimg-oTRPTuwic0Dfh30rq4hiU.jpg?alt=media&token=4adee3a3-909e-43f5-8f17-cc3fcd327b67',
      title: 'Lärarassistent Max',
      subtitle: 'Assisterar lärare med allt de kan tänkas behöva.',
      path: '/app/chat/MCkZkDEFiSKducNHuMvW',
      roles: ['teacher'],
      category: 'Chatbotar',
      label: 'Populär',
    },
    {
      icon: <PsychologyIcon />,
      title: 'Feedback på inlämningsuppgift',
      subtitle: 'Ge konstruktiv feedback på en inlämningsuppgift.',
      path: '/app/fraga-ai?prompt=feedback-inlamning',
      roles: ['teacher'],
      category: 'Lektionsplanering',
    },

    {
      icon: <AssignmentTurnedInIcon />,
      title: 'Skapa individuell studieplan',
      subtitle: 'Generera en anpassad studieplan för en elev',
      path: '/app/fraga-ai?prompt=individuell-studieplan',
      roles: ['teacher'],
      category: 'Lektionsplanering',
    },

    {
      icon: <EventNoteIcon />,
      title: 'Planera tematiskt arbete',
      subtitle: 'Utforma ett ämnesövergripande tematiskt arbete',
      path: '/app/fraga-ai?prompt=tematiskt-arbete',
      roles: ['teacher'],
      category: 'Lektionsplanering',
    },
    {
      icon: <EmojiObjectsIcon />,
      title: 'Generera kreativa övningar',
      subtitle: 'Skapa engagerande och kreativa övningar för ett ämne',
      path: '/app/fraga-ai?prompt=kreativa-ovningar',
      roles: ['teacher'],
      category: 'Undervisningsaktiviteter',
    },
    {
      icon: <FormatQuoteIcon />,
      title: 'Skapa diskussionsfrågor',
      subtitle: 'Generera tankeväckande frågor för gruppdiskussioner',
      path: '/app/fraga-ai?prompt=diskussionsfragor',
      roles: ['teacher'],
      category: 'Undervisningsaktiviteter',
    },
    {
      icon: <Person3Icon />,
      title: 'Skapa din egen AI-karaktär',
      subtitle: 'Skapa en karaktär som underlättar ditt arbete.',
      path: '/app/larare',
      roles: ['teacher'],
      category: 'Chatbotar',
    },
    {
      icon: <BarChartIcon />,
      title: 'Utforma formativ bedömning',
      subtitle: 'Skapa strategier för löpande kunskapsutvärdering',
      path: '/app/fraga-ai?prompt=formativ-bedomning',
      roles: ['teacher'],
      category: 'Bedömning',
    },
    {
      icon: <TranslateIcon />,
      title: 'Språkinlärningsaktiviteter',
      subtitle: 'Generera övningar för att effektivisera språkinlärning.',
      path: '/app/fraga-ai?prompt=sprakinlarning',
      roles: ['teacher'],
      category: 'Ämnesspecifikt',
    },
    {
      icon: <MenuBookIcon />,
      title: 'Skapa läsförståelseuppgifter',
      subtitle: 'Generera uppgifter baserade på en text eller bok',
      path: '/app/fraga-ai?prompt=lasforstaelse',
      roles: ['teacher'],
      category: 'Ämnesspecifikt',
    },
    {
      icon: <CastForEducationIcon />,
      title: 'Planera digitalt lärande',
      subtitle: 'Utforma strategier för effektivt digitalt lärande',
      path: '/app/fraga-ai?prompt=digitalt-larande',
      roles: ['teacher'],
      category: 'Lektionsplanering',
    },
    {
      icon: <GradingIcon />,
      title: 'Betygsätt min uppsats',
      subtitle: 'Betygsätt din uppsats utifrån en bedömningsmatris.',
      path: '/app/uppsats/ny',
      roles: ['student'],
      category: 'Textanalys',
      label: 'Populär',
    },
    {
      icon: <MenuOpenIcon />,
      title: 'Skapa din egen kurs',
      subtitle: 'Generera kurs om valfritt ämne & studera i egen takt.',
      path: '/app/kurser',
      roles: ['student'],
      category: 'Kurser',
      label: 'Nytt',
    },

    {
      icon: <YouTubeIcon />,
      title: 'Skapa quiz från YouTube-klipp',
      subtitle: 'Generera ett quiz baserat på t.ex. en föreläsning från YT.',
      path: '/app/quiz?source=youtube',
      roles: ['student'],
      category: 'Quiz',
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Fspanskaa.png?alt=media&token=be8a3516-14a3-4eba-88bf-85018585cb47',
      title: 'Spansklärare',
      subtitle: 'Chatta eller prata med vår populära spansklärare.',
      path: '/app/chat/nTB3pwXxBdLHbhar839H',
      roles: ['student'],
      category: 'Chatbotar',
      label: 'Populär',
    },
    {
      icon: <TopicIcon />,
      title: 'Skapa quiz från ämne',
      subtitle: 'Generera ett quiz baserat på ett valfritt ämne.',
      path: '/app/quiz?source=topic',
      roles: ['student'],
      category: 'Quiz',
    },

    {
      icon: <WikipediaIcon />,
      title: 'Skapa quiz från Wiki-URL',
      subtitle: 'Skriv in en Wikipedia-URL och få ett quiz.',
      path: '/app/quiz?source=wiki',
      roles: ['student'],
      category: 'Quiz',
    },
    {
      icon: <AttachFileIcon />,
      title: 'Skapa quiz från Word/PDF-fil',
      subtitle: 'Ladda upp din word/PDF-fil och generera ett quiz.',
      path: '/app/quiz?source=word',
      roles: ['student'],
      category: 'Quiz',
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fdr.jpg?alt=media&token=f27a8c83-0e72-42b8-a032-152875566258',
      title: 'Terapeut — samtalspartner',
      subtitle: 'Chatta eller prata med vår populära terapeut.',
      path: '/app/chat/xXhoDvHHb6QxG1H1lmul',
      roles: ['student'],
      category: 'Chatbotar',
    },
    {
      icon: <YouTubeIcon />,
      title: 'Skapa flashcards från YT-klipp',
      subtitle: 'Generera en samling flashcards baserat på en YouTube-video.',
      path: '/app/flashcards?source=youtube',
      roles: ['student'],
      category: 'Flashcards',
    },

    {
      icon: <TopicIcon />,
      title: 'Skapa flashcards från ämne',
      subtitle: 'Generera en samling flashcards baserat på ett valfritt ämne.',
      path: '/app/flashcards?source=topic',
      roles: ['student'],
      category: 'Flashcards',
    },

    {
      icon: <AttachFileIcon />,
      title: 'Skapa flashcards från Word/PDF',
      subtitle: 'Ladda upp din Word/PDF-fil och generera en samling flashcards.',
      path: '/app/flashcards?source=word',
      roles: ['student'],
      category: 'Flashcards',
    },
    {
      icon: <WikipediaIcon />,
      title: 'Skapa flashcards från Wiki-URL',
      subtitle: 'Skriv in en Wikipedia-URL och få en samling flashcards.',
      path: '/app/flashcards?source=wiki',
      roles: ['student'],
      category: 'Flashcards',
    },

    {
      icon: <ShuffleIcon />,
      title: 'Variera text',
      subtitle: 'Omformulera din text utan att ändra innebörden.',
      path: '/app/textlabbet?function=variera',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: <SummarizeIcon />,
      title: 'Summera text',
      subtitle: 'Sammanfatta din text för att extrahera det viktigaste.',
      path: '/app/textlabbet?function=summera',
      roles: ['student'],
      category: 'Textbearbetning',
      label: 'Populär',
    },
    {
      icon: <SpellcheckIcon />,
      title: 'Rätta text',
      subtitle: 'Korrigera stavfel och grammatiska fel i din text.',
      path: '/app/textlabbet?function=ratta',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: <FormatAlignLeftIcon />,
      title: 'Formatera text',
      subtitle: 'Förbättra textens struktur och läsbarhet.',
      path: '/app/textlabbet?function=formatera',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: <FactCheckIcon />,
      title: 'Faktakolla text',
      subtitle: 'Verifiera påståenden i din text och säkerställ att den är korrekt.',
      path: '/app/textlabbet?function=faktakolla',
      roles: ['student'],
      category: 'Textanalys',
    },
    {
      icon: <FormatListBulletedIcon />,
      title: 'Skapa punktlista',
      subtitle: 'Omvandla din text till en tydlig punktlista.',
      path: '/app/textlabbet?function=punktlista',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-5pEWKA4Etn2t5V56FxRJF.jpg?alt=media&token=0bb45f0a-38eb-48da-8ac8-9eb21273c1f8',
      title: 'Motiverade coach',
      subtitle: 'Hjälper elever att sätta upp och nå sina mål.',
      path: '/app/chat/fSHkPlbq00VHgSK8carT',
      roles: ['student'],
      category: 'Chatbotar',
    },
    {
      icon: <TrendingUpIcon />,
      title: 'Förbättra text',
      subtitle: 'Förbättra textens flyt och ton, gör den mer engagerande.',
      path: '/app/textlabbet?function=forbattra',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: <RateReviewIcon />,
      title: 'Kritisera text',
      subtitle: 'Ge konstruktiv kritik på textens innehåll och upplägg.',
      path: '/app/textlabbet?function=kritisera',
      roles: ['student'],
      category: 'Textanalys',
    },
    {
      icon: <ViewStreamIcon />,
      title: 'Omstrukturera text',
      subtitle: 'Ändra textens struktur för bättre flöde.',
      path: '/app/textlabbet?function=omstrukturera',
      roles: ['student'],
      category: 'Textbearbetning',
    },
    {
      icon: <GavelIcon />,
      title: 'Argumentationsanalys',
      subtitle: 'Identifiera och utvärdera argument som presenteras i texten.',
      path: '/app/textlabbet?function=argumentanalysera',
      roles: ['student'],
      category: 'Textanalys',
    },
    {
      icon: <CompareArrowsIcon />,
      title: 'Jämför koncept & idéer',
      subtitle: 'Jämför olika koncept eller idéer i texten.',
      path: '/app/textlabbet?function=konceptideer',
      roles: ['student'],
      category: 'Textanalys',
    },
    {
      icon: <LinkIcon />,
      title: 'Intertextuell analys',
      subtitle: 'Hitta kopplingar till andra texter eller verk.',
      path: '/app/textlabbet?function=intertextuellanalys',
      roles: ['student'],
      category: 'Textanalys',
    },
    {
      icon: <AssignmentIcon />,
      title: 'Generera studieuppgifter',
      subtitle: 'Skapa uppgifter baserade på textens innehåll.',
      path: '/app/textlabbet?function=generera-studieuppgifter',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <QuizIcon />,
      title: 'Generera flervalsfrågor',
      subtitle: 'Skapa flervalsfrågor baserade på texten.',
      path: '/app/textlabbet?function=generera-flervalsfragor',
      roles: ['teacher'],
      category: 'Undervisningsaktiviteter',
    },
    {
      icon: <TextFieldsIcon />,
      title: 'Generera lucktext',
      subtitle: 'Skapa lucktexter baserade på innehållet.',
      path: '/app/textlabbet?function=generera-lucktext',
      roles: ['teacher'],
      category: 'Undervisningsaktiviteter',
    },
    {
      icon: <PsychologyIcon />,
      title: 'Metakognitiva reflektioner',
      subtitle: 'Skapa frågor för att utveckla metakognitiva förmågor',
      path: '/app/fraga-ai?prompt=metakognition',
      roles: ['teacher'],
      category: 'Undervisningsaktiviteter',
    },

    {
      icon: <AutoFixHighIcon />,
      title: 'Skapa studieplan',
      subtitle: 'Generera en personlig studieplan baserad på dina mål',
      path: '/app/fraga-ai?prompt=skapa-studieplan',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <FormatListBulletedIcon />,
      title: 'Sammanfattningsteknik',
      subtitle: 'Lär dig effektiva metoder för att sammanfatta texter',
      path: '/app/fraga-ai?prompt=sammanfattningsteknik',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <EmojiObjectsIcon />,
      title: 'Minnestekniker',
      subtitle: 'Upptäck kraftfulla minnestekniker för bättre inlärning',
      path: '/app/fraga-ai?prompt=minnestekniker',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <AccessTimeIcon />,
      title: 'Tidshantering',
      subtitle: 'Få tips för effektiv tidshantering i dina studier',
      path: '/app/fraga-ai?prompt=tidshantering',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <MenuBookIcon />,
      title: 'Lästekniker',
      subtitle: 'Lär dig effektiva tekniker för att läsa och förstå texter',
      path: '/app/fraga-ai?prompt=lastekniker',
      roles: ['student'],
      category: 'Studiehjälp',
    },
    {
      icon: <PsychologyIcon />,
      title: 'Koncentrationstekniker',
      subtitle: 'Förbättra din koncentration och fokus under studier',
      path: '/app/fraga-ai?prompt=koncentrationstekniker',
      roles: ['student'],
      category: 'Studiehjälp',
    },

    {
      icon: <AccessTimeIcon />,
      title: 'Effektiv studieteknik',
      subtitle: 'Få tips och strategier för effektivt lärande.',
      path: '/app/fraga-ai?prompt=studieteknik',
      roles: ['student'],
      category: 'Studiehjälp',
    },

    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-Fu6l0eyphTZ05J5z6Glqn.jpg?alt=media&token=a7b57577-19db-48d7-9dc8-148b0be1781c',
      title: 'Studievägledare',
      subtitle: 'Chatta eller prata med en studievägledare.',
      path: '/app/chat/FWtOI0nejxC8gEWqkI7v',
      roles: ['student'],
      category: 'Chatbotar',
    },

    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2Fimg-EzrGzwpDbj6UV2yjXhCtR.jpg?alt=media&token=1cebd225-7332-43c1-95de-159cfaed0791',
      title: 'Hälso- och sömnrådgivare',
      subtitle: 'Optimera studierna genom god hälsa.',
      path: '/app/chat/IieasArTBXvs2HKID7pX',
      roles: ['student'],
      category: 'Chatbotar',
    },

    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ftyska.png?alt=media&token=9ed00390-34ef-427c-9a7c-a70e5f1bed0e',
      title: 'Tysklärare',
      subtitle: 'Plugga på din tyska tillsammans med Johan Bauer.',
      path: '/app/chat/14GRiHrsHh2f3YDKilDq',
      roles: ['student'],
      category: 'Chatbotar',
    },

    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fl%C3%A4rare%2Ffranska.png?alt=media&token=5ab54912-a63a-405a-bafa-18f4acae8ea7',
      title: 'Fransklärare',
      subtitle: 'Plugga på din franska tillsammans med Marie Dubois.',
      path: '/app/chat/3dRmkg2iFEyDa5vPKTUy',
      roles: ['student'],
      category: 'Chatbotar',
    },

    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/teacherImages%2FZ6BLSMsm38Un5mgW0O6Z3TToHq12%2Fastr.jpg?alt=media&token=7998469d-e220-422b-905f-7c430f68a099',
      title: 'KBT-terapeut',
      subtitle: 'Fokuserar på att förändra negativa tankemönster.',
      path: '/app/chat/qtlfShBM0ufub6oT09CT',
      roles: ['student'],
      category: 'Chatbotar',
      label: 'Populär',
    },

    {
      icon: <Person3Icon />,
      title: 'Skapa din egen AI-karaktär',
      subtitle: 'Bestäm exakt hur du vill att karaktären ska bete sig.',
      path: '/app/larare',
      roles: ['student'],
      category: 'Chatbotar',
    },

    // {
    //   icon: <Spellcheck />,
    //   title: 'Högskoleprovet - ORD',
    //   subtitle: 'Träna dig på ORD-frågor från de senaste årens tester.',
    //   path: '/app/hp/F8CaAdYcKBH0K7X5bhZX',
    //   roles: ['student'],
    //   category: 'Högskoleprovet',
    // },

    // {
    //   icon: <AutoStories />,
    //   title: 'Högskoleprovet - LÄS',
    //   subtitle: 'Träna dig på LÄS-frågor från de senaste årens tester.',
    //   path: '/app/hp/F8CaAdYcKBH0K7X5bhZX',
    //   roles: ['student'],
    //   category: 'Högskoleprovet',
    // },
  ];
  // import { AutoStories, Spellcheck, TextFields, ElectricBolt, Calculate, Extension, Language } from '@mui/icons-material';
  //   ORD: <Spellcheck />,
  //   LÄS: <AutoStories />,
  //   MEK: <TextFields />,
  //   ELF: <Language />,
  //   XYZ: <Calculate />,
  //   DTK: <Extension />

  const filteredShortcuts = shortcuts.filter(
    shortcut =>
      (shortcut.roles.includes(role) || shortcut.roles.includes('both')) &&
      (selectedCategory === 'Alla' || shortcut.category === selectedCategory)
  );

  const categories = [
    'Alla',
    ...(role === 'student' ? ['Chatbotar'] : []),
    ...new Set(
      shortcuts
        .filter(shortcut => shortcut.roles.includes(role) || shortcut.roles.includes('both'))
        .map(shortcut => shortcut.category)
        .filter(category => category !== 'Alla' && (role !== 'student' || category !== 'Chatbotar'))
    ),
  ].filter(Boolean);

  useEffect(() => {
    console.log('category selected: ', selectedCategory);
  }, [selectedCategory]);

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
        {categories.map((category, index) => (
          <Button
            key={index}
            variant={selectedCategory === category ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory(category)}
            startIcon={category === 'Alla' ? <GenerateIcon /> : null}
            sx={{
              borderRadius: '30px',
              textTransform: 'none',
              fontWeight: '400',
              borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
              '&:hover': {
                borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
              },
            }}
          >
            {category}
          </Button>
        ))}
      </Box>
      <Grid container spacing={2}>
        {filteredShortcuts.map((shortcut, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ShortcutBox {...shortcut} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardToolsShortcuts;
