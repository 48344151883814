import React, { useState, useEffect } from 'react';
import {
    Grid,
    CssBaseline,
    Avatar,
    Box,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Sidebar from './Sidebar';

import HistoryTeacherView from './HistoryTeacherView';
import HomeworkHelperView from './HomeworkHelperView';
import FlashcardsView from './FlashcardsView';
import BookReader from './BookReader';
import Courses from './Courses';
import CoursesPublic from './CoursesPublic';
import SpanishChat from './SpanishChat';
import SharedConversation from './SharedConversation';
import Essays from './Essays';
import WritingAssistant from './WritingAssistant';
import TextManager from './TextManager';
import AskAI from './AskAI';
import FlashcardsCreateView from './FlashcardsCreateView';
import Logger from './Logger';
import TypeWriter from './TypeWriter';
import EssayResult from './EssayResult';
import DebateView from './DebateView';
import Rubrics from './Rubrics';
import Dashboard from './Dashboard';
import TeacherDetails from './TeacherDetails';
import QuizView from './QuizView';
import PublicTeachers from './PublicTeachers';
import PublicTeachers2 from './PublicTeachers2';
import PublicQuiz from './PublicQuiz';

import WordView from './WordView';
import EssayUpload from './EssayUpload';

import LanguageTest from './LanguageTest';

import HP from './HP';
import PublicHP from './PublicHP';

import DetectorView from './DetectorView';
import EssayWriterView from './EssayWriterView';
import NotesView from './NotesView';
import CreateAccountView from './CreateAccountView';

import { useParams } from 'react-router-dom';

import LoadingSpinner from './LoadingSpinner';

import { useAuth } from './useAuth';

import TeacherChat from './TeacherChat';
import TeacherDialog from './TeacherDialog';
import TeacherHandler from './TeacherHandler';

import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';

import { GoogleOAuthProvider } from '@react-oauth/google';

const TeacherChatWrapper = props => {
    const { teacherId } = useParams();
    return <TeacherChat key={teacherId} {...props} teacherId={teacherId} />;
};

function ExistingApp({ isDark, toggleDarkMode, setOverrideTheme }) {
    const { user, loading, handleGoogleLogin } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const [googleOneTapInitialized, setGoogleOneTapInitialized] = useState(false);

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [shouldRedirect, setShouldRedirect] = useState(false);
    const theme = useTheme();
    const styles = getStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

    useEffect(() => {
        setSidebarOpen(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        const initializeGoogleOneTap = () => {
            if (
                !loading &&
                user.guest &&
                !googleOneTapInitialized &&
                window.google &&
                window.google.accounts &&
                window.google.accounts.id
            ) {
                window.google.accounts.id.initialize({
                    client_id:
                        '981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com',
                    callback: handleGoogleLogin,
                    auto_select: true,
                    use_fedcm_for_prompt: true,
                });

                setTimeout(() => {
                    window.google.accounts.id.prompt();
                }, 100);
                setGoogleOneTapInitialized(true);
            }
        };

        // Only run the initialization when loading is false (user state is determined)
        if (!loading) {
            initializeGoogleOneTap();
        }

        return () => {
            if (window.google && window.google.accounts && window.google.accounts.id) {
                window.google.accounts.id.cancel();
            }
        };
    }, [user.guest, handleGoogleLogin, loading, googleOneTapInitialized]);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleSidebar = () => {
        setSidebarOpen(prevState => !prevState);
    };

    const toggleDarkModish = () => {
        Logger.log('toggleDarkModish user.preferredTheme: ', user.preferredTheme);
        toggleDarkMode();
    };

    const goToLoginPage = () => {
        window.location.href = '/login';
    };

    return (
        <GoogleOAuthProvider clientId='981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com'>
            <HelmetProvider>
                <Grid
                    container
                    component='main'
                    sx={{ height: '100vh', backgroundColor: isDark ? '#15202B' : '#f1f5f9' }}
                >
                    <CssBaseline />
                    <Sidebar
                        isOpen={sidebarOpen}
                        toggleSidebar={toggleSidebar}
                        isDark={isDark}
                        toggleDarkMode={toggleDarkModish}
                        setOverrideTheme={setOverrideTheme}
                        onAddTeacher={() => setDialogOpen(true)}
                    />

                    <TeacherDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />

                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginLeft: { xs: 0, md: sidebarOpen ? '270px' : 10 },

                            paddingLeft: { xs: '0px', md: '30px' },
                            width: {
                                xs: '100%',
                                md: sidebarOpen ? 'calc(100% - 270px)' : '100%',
                            },
                            transition: 'margin-left 0.1s, width 0.1s',
                        }}
                    >
                        {loading ? (
                            <LoadingSpinner size='20' />
                        ) : (
                            <>
                                {/*
                        {user && (
                        <Box sx={{ position: 'absolute', top: 10, right: 16, zIndex: 999, display:{xs: 'none', md: 'block'} }}>
                            {user.guest ? (

                                    <IconButton onClick={goToLoginPage}>
                                        <Avatar src={user.photoURL} alt={user.displayName} />
                                    </IconButton>

                            ) : (
                             <IconButton onClick={handleMenuOpen}>
                                    <Avatar src={user.photoURL} alt={user.displayName} sx={{ width: 30, height: 30 }} />
                                </IconButton>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleLogoutClick(); handleMenuClose(); }}>
                                    <LogoutIcon sx={{ mr: 1 }} />
                                    Logga ut
                                </MenuItem>
                            </Menu>
                        </Box>
                    )}

*/}

                                <Box
                                    sx={{
                                        height: '100%',
                                        position: 'relative',
                                        flexBasis: { xs: '100% !important' },
                                    }}
                                >
                                    {shouldRedirect ? (
                                        <Routes>
                                            <Route
                                                path='/login'
                                                element={
                                                    <PageWrapper
                                                        component={CreateAccountView}
                                                        setUser={user}
                                                        title='Logga In'
                                                        description='Logga in på ditt konto.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='*'
                                                element={<Navigate to='/login' replace />}
                                            />
                                        </Routes>
                                    ) : (
                                        <Routes>
                                            {/* Home route */}
                                            <Route
                                                path='/'
                                                element={
                                                    <PageWrapper
                                                        component={Dashboard}
                                                        user={user}
                                                        title='Studera.AI'
                                                        description='Förbättra ditt lärande med Studera.AI. Generera flashcards, quiz och högskoleprov, samt chatta med AI-lärare för personlig hjälp.'
                                                    />
                                                }
                                            />
                                            {/* Routes with parameters */}
                                            <Route
                                                path='chat/:teacherId'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TeacherChatWrapper}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/larare/:teacherId'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TeacherHandler}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Hantera lärare'
                                                        description='Chatta med vår AI och få svar på dina frågor.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/larare/:teacherId/:teacherName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TeacherDetails}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/matris/ny'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Rubrics}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Skapa bedömningsmatris | Studera.AI'
                                                        description='Skapa en bedömningsmatris som sedan används för att med AI bedöma den uppsatsen du laddar upp.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/uppsats/ny'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={EssayUpload}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Ladda upp uppsats | Studera.AI'
                                                        description='Ladda upp din uppsats och få den bedömd av vår AI utifrån bedömningsmatriser.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/uppsatser'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Essays}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Dina uppsatser | Studera.AI'
                                                        description='Här hittar du de uppdatser du laddat upp för att få betygsatt av AI.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/uppsats/:docId'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={EssayResult}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Uppsats | Studera.AI'
                                                        description='X'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/kurser/gratis-kurser'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={CoursesPublic}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Gratis kurser | Studera.AI'
                                                        description='Använd de gratis kurser som våra användare skapat för att lära dig mer om valfritt ämne.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/kurser/:courseName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Courses}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/kurser/:courseName/:moduleName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Courses}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/kurser/:courseName/:moduleName/:chapterName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Courses}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/flashcards/:collectionId/:collectionName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={FlashcardsView}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/quiz/:quizId/:urlQuizName'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={QuizView}
                                                        user={user}
                                                        isDark={isDark}
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/hp/:testId?'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={HP}
                                                        user={user}
                                                        isDark={isDark}
                                                        key={window.location.pathname}
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/delad-konversation/:chatId'
                                                element={<SharedConversation />}
                                            />

                                            <Route
                                                path='/shared-conversation'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={SharedConversation}
                                                        user={user}
                                                        isDark={isDark}
                                                        title=''
                                                        description=''
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/larare'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TeacherHandler}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Skapa AI-karaktär | Studera.AI'
                                                        description='Designa din egen AI-karaktär för personlig och interaktiv inlärning om alla tänkbara ämnen och språk.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/hogskoleprov'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={PublicHP}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Alla högskoleprov — Gamla högskoleprov & AI-genererade | Studera.AI'
                                                        description='Öva på gamla och AI-genererade högskoleprov. Förbered dig effektivt med vår omfattande provbank.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/personal'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={PublicTeachers}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI-lärare, karaktärer och mentorer | Studera.AI'
                                                        description='Utforska vår samling av AI-lärare och mentorer. Få personlig hjälp och vägledning i dina studier.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/personal2'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={PublicTeachers2}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI-lärare, karaktärer och mentorer | Studera.AI'
                                                        description='Utforska vår samling av AI-lärare och mentorer. Få personlig hjälp och vägledning i dina studier.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/spraktest'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={LanguageTest}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Språktest | Studera.AI'
                                                        description='Läs och studera smidigt med vår digitala bokreader. Förbättra din läsförståelse med AI-stöd.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/bok'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={BookReader}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Digital Bokreader | Studera.AI'
                                                        description='Läs och studera smidigt med vår digitala bokreader. Förbättra din läsförståelse med AI-stöd.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/kurser'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={Courses}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Kurser | Studera.AI'
                                                        description='Läs och studera smidigt med vår digitala bokreader. Förbättra din läsförståelse med AI-stöd.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/write'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={WritingAssistant}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI Skrivhjälp | Studera.AI'
                                                        description='Förbättra ditt skrivande med vår AI-drivna skrivassistent. Få hjälp med struktur, grammatik och stil.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/fraga-ai'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={AskAI}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Fråga AI | Studera.AI'
                                                        description='Chatta med vår avancerade AI för snabb och pålitlig studiehjälp. Fråga AI vad som helst!'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/quiz'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={QuizView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Skapa quiz med AI | Studera.AI'
                                                        description='Skapa AI-genererade quiz baserade på ämne, längre text eller Word-dokument. Lär dig medan du har kul!'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/alla-quiz'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={PublicQuiz}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Quiz-bibliotek | Studera.AI'
                                                        description='Utforska vårt omfattande bibliotek av AI-genererade quiz inom olika ämnen. Hitta den perfekta utmaningen för dig.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/skrivmaskinen'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TypeWriter}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Generera text med AI | Studera.AI'
                                                        description='Använd vår AI-baserade skrivmaskin för att generera text. Gör skrivprocessen mer levande!'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/debatt'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={DebateView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI Debattforum | Studera.AI'
                                                        description='Öva dina debattfärdigheter och kritiskt tänkande i vårt AI-drivna debattforum.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/textlabbet'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={TextManager}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Textlabbet - Textverktyg | Studera.AI'
                                                        description='Förbättra dina texter med 13 kraftfulla AI-verktyg. Perfekt för studenter, akademiker och professionella skribenter.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/ai-detektor'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={DetectorView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI-Detektor | Studera.AI'
                                                        description='Analysera och identifiera AI-genererad text på svenska med vår avancerade AI-detektor.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/sparat'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={NotesView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Dina sparade anteckningar | Studera.AI'
                                                        description='Hantera och organisera dina studiematerial effektivt med vårt smarta anteckningssystem. Allt du sparar på sidan hamnar här.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/laxhjalp'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={HomeworkHelperView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI Läxhjälp | Studera.AI'
                                                        description='Få personlig hjälp med dina läxor från vår AI-assistent. Förbättra dina studieresultat.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/flashcards'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={FlashcardsView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Skapa flashcards med AI | Studera.AI'
                                                        description='Skapa och använd AI-genererade flashcards för effektiv memorering och repetition av studiematerial.'
                                                    />
                                                }
                                            />
                                            <Route
                                                path='/skapa-flashcards'
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={FlashcardsCreateView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Skapa flashcards med AI  | Studera.AI'
                                                        description='kapa och använd AI-genererade flashcards för effektiv memorering och repetition av studiematerial.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/hogskoleprovet-verbal-del'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={WordView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='Högskoleprovet Verbal Del | Studera.AI'
                                                        description='Träna på den verbala delen av högskoleprovet. Förbättra ditt ordförråd och din läsförståelse.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/skriv-uppsats'
                                                noIndex={true}
                                                element={
                                                    <PageWrapper
                                                        isOpen={sidebarOpen}
                                                        component={EssayWriterView}
                                                        user={user}
                                                        isDark={isDark}
                                                        title='AI Uppsatshjälp | Studera.AI'
                                                        description='Få hjälp att strukturera och skriva bättre uppsatser med vår AI-drivna uppsatsassistent.'
                                                    />
                                                }
                                            />

                                            <Route
                                                path='/login'
                                                element={
                                                    <PageWrapper
                                                        component={CreateAccountView}
                                                        setUser={user}
                                                        title='Logga in på Studera.AI'
                                                        description='Skapa konto eller logga in för att få tillgång till alla Studera.AI:s funktioner och personlig inlärning. Det är helt gratis.'
                                                    />
                                                }
                                            />

                                            {/* Catch-all route for undefined paths */}
                                            <Route path='*' element={<Navigate to='/' />} />
                                        </Routes>
                                    )}
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </HelmetProvider>
        </GoogleOAuthProvider>
    );
}

const PageWrapper = ({
    component: Component,
    user,
    isOpen,
    title,
    description,
    isDark,
    noIndex,
}) => {
    const params = useParams();

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description} />
                {noIndex && <meta name='robots' content='noindex' />} {/* Add this line */}
            </Helmet>
            <Component user={user} isOpen={isOpen} isDark={isDark} {...params} />
        </>
    );
};

export default ExistingApp;
