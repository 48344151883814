import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import FunctionsIcon from '@mui/icons-material/Functions';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NatureIcon from '@mui/icons-material/Nature';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BiotechIcon from '@mui/icons-material/Biotech';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SpaIcon from '@mui/icons-material/Spa';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SavingsIcon from '@mui/icons-material/Savings';
import SchoolIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import CalculateIcon from '@mui/icons-material/Calculate';
import RefreshIcon from '@mui/icons-material/Refresh';
import TranslateIcon from '@mui/icons-material/Translate';
import ScienceIcon from '@mui/icons-material/Science';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';

const PromptBox = ({ icon, text, onClick, theme }) => (
  <Box
    sx={{
      width: '170px',
      height: '130px',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
      boxShadow: theme.palette.mode === 'dark' ? 'none' : 'rgba(99, 99, 99, 0.11) 0px 2px 8px 0px',
      borderRadius: '15px',
      border: theme.palette.mode === 'dark' ? '1px solid #323d48' : '1px solid #e2e8f0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '12px',

      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#fdfdfd',
      },
    }}
    onClick={onClick}
  >
    <IconButton
      sx={{
        alignSelf: 'flex-start',
        color: 'white',
        fontSize: '10px',
        padding: '0px',
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'gray',
        },
      }}
    >
      {icon}
    </IconButton>
    <Typography
      variant='body2'
      sx={{
        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'gray',
        textAlign: 'left',
        marginTop: '11px',
        fontWeight: '500',
        overflowWrap: 'break-word',
      }}
    >
      {text}
    </Typography>
  </Box>
);

const PromptBoxes = ({ handleMenuItemClick, theme }) => {
  const [displayedPrompts, setDisplayedPrompts] = useState([]);

  const randomizePrompts = () => {
    const shuffled = [...prompts].sort(() => 0.5 - Math.random());
    setDisplayedPrompts(shuffled.slice(0, 4));
  };

  useEffect(() => {
    randomizePrompts();
  }, []);

  const prompts = [
    {
      icon: <MenuBookIcon />,
      text: 'Sammanfatta en akademisk artikel',
      prompt:
        'Hjälp mig att sammanfatta huvudpunkterna i en akademisk artikel inom mitt studieområde.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara statistisk analys',
      prompt:
        'Förklara grunderna i statistisk analys och dess tillämpningar inom forskningsmetodik.',
    },
    {
      icon: <SchoolIcon />,
      text: 'Skapa en studieplan',
      prompt: 'Hjälp mig att skapa en effektiv studieplan för att förbereda mig inför tentamen.',
    },
    {
      icon: <AccessTimeIcon />,
      text: 'Tips för tidshantering',
      prompt: 'Ge mig strategier för att hantera min tid effektivt som universitetsstudent.',
    },
    {
      icon: <HistoryIcon />,
      text: 'Analysera en historisk händelse',
      prompt:
        'Hjälp mig att analysera orsakerna och konsekvenserna av en viktig historisk händelse.',
    },
    {
      icon: <ScienceIcon />,
      text: 'Förklara den vetenskaplig metoden',
      prompt: 'Beskriv den vetenskapliga metoden och hur den tillämpas inom akademisk forskning.',
    },
    {
      icon: <PsychologyIcon />,
      text: 'Diskutera psykologisk teori',
      prompt: 'Förklara en central psykologisk teori och dess relevans i modern forskning.',
    },
    {
      icon: <LanguageIcon />,
      text: 'Tips för akademisk engelska',
      prompt:
        'Ge mig tips för att förbättra min akademiska engelska, särskilt för uppsatsskrivning.',
    },
    {
      icon: <AccountBalanceIcon />,
      text: 'Förklara en ekonomisk modell',
      prompt:
        'Beskriv en grundläggande ekonomisk modell och dess tillämpningar i verkliga situationer.',
    },
    {
      icon: <CodeIcon />,
      text: 'Hjälp med programmeringskoncept',
      prompt: 'Förklara ett avancerat programmeringskoncept och ge ett exempel på dess användning.',
    },
    {
      icon: <BiotechIcon />,
      text: 'Förklara ett genetiskt begrepp',
      prompt: 'Beskriv ett komplext genetiskt begrepp och dess betydelse inom modern biologi.',
    },
    {
      icon: <LightbulbIcon />,
      text: 'Brainstorma forskningsidéer',
      prompt: 'Hjälp mig att brainstorma potentiella forskningsämnen inom mitt studieområde.',
    },
    {
      icon: <PublicIcon />,
      text: 'Fråga mig om världens huvudstäder',
      prompt:
        'Vi börjar med en frågesport om världens huvudstäder för att förbättra mina geografikunskaper. Fråga mig först om huvudstaden i vilket land du vill, så ska jag försöka svara rätt.',
    },

    {
      icon: <MenuBookIcon />,
      text: 'Skriv en kort novell om vänskap',
      prompt:
        'Skriv en kort novell på 500 ord som utforskar temat vänskap mellan två oväntade karaktärer.',
    },
    {
      icon: <LightbulbIcon />,
      text: 'Förklara AI för nybörjare',
      prompt:
        'Förklara artificiell intelligens på ett enkelt sätt för någon utan teknisk bakgrund.',
    },
    {
      icon: <NatureIcon />,
      text: 'Skriv en dikt om naturen',
      prompt: 'Skriv en dikt som fångar naturens skönhet och kraft.',
    },
    {
      icon: <FitnessCenterIcon />,
      text: 'Utveckla en träningsrutin',
      prompt: 'Skapa en effektiv träningsrutin för nybörjare som vill komma i form.',
    },
    {
      icon: <AcUnitIcon />,
      text: 'Diskutera klimatförändringar',
      prompt: 'Diskutera orsakerna till klimatförändringar och möjliga lösningar.',
    },
    {
      icon: <BiotechIcon />,
      text: 'Förklara fotosyntes',
      prompt: 'Förklara processen fotosyntes på ett enkelt sätt.',
    },
    {
      icon: <AccessTimeIcon />,
      text: 'Beskriv en dag i framtiden',
      prompt: 'Föreställ dig hur en vanlig dag kommer att se ut om 20 år och beskriv den.',
    },
    {
      icon: <SpaIcon />,
      text: 'Skapa en plan för stresshantering',
      prompt: 'Utveckla en strategi för att hantera stress i vardagen.',
    },
    {
      icon: <RocketLaunchIcon />,
      text: 'Skriv en kort science fiction-berättelse',
      prompt: 'Skriv en kort berättelse inom science fiction-genren med en oväntad twist.',
    },
    {
      icon: <LocalLibraryIcon />,
      text: 'Skapa en lista över bokrekommendationer',
      prompt: 'Lista och beskriv fem böcker som alla borde läsa minst en gång.',
    },
    {
      icon: <SavingsIcon />,
      text: 'Skapa en plan för personlig ekonomi',
      prompt: 'Utveckla en plan för att hantera din personliga ekonomi och spara pengar.',
    },
    {
      icon: <MenuBookIcon />,
      text: 'Analysera en klassisk litterär text',
      prompt: 'Analysera temat och karaktärsutvecklingen i "Hamlet" av Shakespeare.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara trigonometri för nybörjare',
      prompt: 'Ge en enkel förklaring av grundläggande trigonometri och dess tillämpningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Öva engelska idiom',
      prompt: 'Förklara fem engelska idiom och använd dem i exempelmeningar.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara sannolikhetsteori',
      prompt: 'Ge en grundläggande förklaring av sannolikhet och ge exempel på dess användning.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Lös en andragradsekvation',
      prompt: 'Lös ekvationen x² - 5x + 6 = 0 och förklara lösningsmetoden.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig japanska siffror',
      prompt: 'Lista de japanska siffrorna 1-10 och använd dem i exempelmeningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Öva spanska konjunktioner',
      prompt: 'Lista fem vanliga spanska konjunktioner och använd dem i komplexa meningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig koreanska hälsningsfraser',
      prompt: 'Lista tio vanliga koreanska hälsningsfraser och deras betydelser.',
    },
    {
      icon: <HistoryIcon />,
      text: 'Beskriv den franska revolutionen',
      prompt: 'Förklara orsakerna till den franska revolutionen och dess konsekvenser för Europa.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig portugisiska fraser för resor',
      prompt:
        'Lista fem viktiga portugisiska fraser som är användbara för resenärer och använd dem i exempelmeningar.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Lös exponentiella ekvationer',
      prompt: 'Lös ekvationen 2^(x) = 16 och förklara stegen.',
    },
  ];

  return (
    <Box sx={{ position: 'relative', marginTop: '0px', textAlign: 'center' }}>
      <IconButton
        onClick={randomizePrompts}
        sx={{
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#c1cbd5',

          marginBottom: '10px',
          '& .MuiSvgIcon-root': {
            fontSize: '14px',
          },
        }}
      >
        <RefreshIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        {displayedPrompts.map((item, index) => (
          <PromptBox
            key={index}
            icon={item.icon}
            text={item.text}
            theme={theme}
            onClick={() => handleMenuItemClick(item.prompt)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PromptBoxes;
