import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import Person3Icon from '@mui/icons-material/Person3';

const ShortcutBox = ({ icon, title, path }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.19)',
          borderRadius: '12px',
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)',
            transform: 'translateY(-2px)',
            '& .icon, & .chevron': {
              color: isDark ? 'rgb(16, 151, 218)' : '#6b46c1', // Darker purple on hover
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {React.cloneElement(icon, {
            sx: {
              fontSize: 24,
              color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#8b5cf6',
              mr: 2,
              transition: 'color 0.3s ease',
            },
            className: 'icon',
          })}
          <Typography
            variant='subtitle1'
            sx={{
              color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#4a5568',
              fontWeight: 500,
              fontSize: 15,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ChevronRightIcon
            sx={{
              fontSize: 24,
              color: isDark ? 'rgba(255, 255, 255, 0.5)' : '#8b5cf6',
              transition: 'color 0.3s ease',
            }}
            className='chevron'
          />
        </Box>
      </Box>
    </Link>
  );
};

const DashboardCreateShortcuts = () => {
  const shortcuts = [
    { icon: <AutoFixHighIcon />, title: 'Skapa karaktärer med AI', path: '/app/larare' },
    { icon: <AutoFixHighIcon />, title: 'Skapa quiz med AI', path: '/app/quiz' },

    { icon: <AutoFixHighIcon />, title: 'Skapa flashcards med AI', path: '/app/flashcards' },

    //{ icon: <AutoFixHighIcon />, title: 'Skapa högskoleprov med AI', path: '/app/hp' },
    { icon: <AutoFixHighIcon />, title: 'Skapa kurs med AI', path: '/app/kurser' },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {shortcuts.map((shortcut, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <ShortcutBox {...shortcut} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardCreateShortcuts;
